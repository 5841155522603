import * as React from 'react'
import { Button } from '@chakra-ui/core'
import { observer } from 'mobx-react'

import { useStores } from 'models'

export const LogOutButton = observer(() => {
  const { User } = useStores()

  return (
    <Button className="btn danger" variant="outline" onClick={User.SignOut}>
      Logga ut
    </Button>
  )
})
