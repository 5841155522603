import React from 'react'
import { List } from '@chakra-ui/core'

import { IAuction } from 'models/Auction'
import { SharedAuctionsListItem } from './SharedAuctionsListItem'

export interface ISharedAuctionsListProps {
  auctions: IAuction[]
}

export const SharedAuctionsList: React.FC<ISharedAuctionsListProps> = ({ auctions }) => (
  <List paddingLeft={['0', '1rem', '2rem']} pr={['0', '1rem', '2rem']}>
    {auctions.map((auction) => (
      <SharedAuctionsListItem key={auction.id} auction={auction} />
    ))}
  </List>
)
