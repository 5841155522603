import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/firestore'
import { IImageData } from 'models/Images'
import { ICallData } from 'models/Call'

export type MissingImage = {
  callId: string
  callNumber: number
  filename: string
  callHeadline: string
}

/**
 * Returns an array of images missing.
 */
export async function addMissingStorageUrls(auctionID: string): Promise<MissingImage[]> {
  const callsRef = firebase.firestore().collection(`/auctions/${auctionID}/calls`)
  const calls = await callsRef.get()

  const missingImages: MissingImage[] = []

  await Promise.all(
    calls.docs.map(async (call) => {
      const { callNumber, headline } = call.data() as ICallData
      const imagesRef = callsRef.doc(call.id).collection('images')

      const images = await imagesRef.get()

      await Promise.all(
        images.docs.map(async (image) => {
          const { filename } = image.data() as IImageData
          const storageImagePath = `callImages/${auctionID}/${call.id}/${filename}`
          const storageImageRef = firebase.storage().ref(storageImagePath)

          try {
            const url = await storageImageRef.getDownloadURL()

            await image.ref.update({
              oldUrl: image.data().url,
              url
            })
          } catch (error) {
            if (
              typeof error === 'object' &&
              error !== null &&
              'code' in error &&
              (error as any).code === 'storage/object-not-found'
            ) {
              missingImages.push({
                callId: call.id,
                callNumber: Number(callNumber),
                callHeadline: headline,
                filename
              })
            } else {
              throw error
            }
          }
        })
      )
    })
  )

  return missingImages
}

export async function addMissingImageFirestoreDocuments(auctionID: string): Promise<void> {
  const callsRef = firebase.firestore().collection(`/auctions/${auctionID}/calls`)
  const calls = await callsRef.get()

  await Promise.all(
    calls.docs.map(async (call) => {
      const callImagesRef = callsRef.doc(call.id).collection('images')
      const callImagesPath = `callImages/${auctionID}/${call.id}`

      const callImagesStorageRef = firebase.storage().ref(callImagesPath)

      const listResult = await callImagesStorageRef.listAll()

      await Promise.all(
        listResult.items.map(async (image) => {
          const imagesSnapshot = await callImagesRef.where('filename', '==', image.name).get()

          if (imagesSnapshot.size > 0) {
            return
          }

          const { id } = callImagesRef.doc()

          const imageData: IImageData = {
            callID: call.id,
            filename: image.name,
            id,
            imageTaken: firebase.firestore.Timestamp.now(),
            storagePath: `${callImagesPath}/${image.name}`,
            url: await image.getDownloadURL()
          }

          await callImagesRef.doc(id).set(imageData)
        })
      )
    })
  )
}
