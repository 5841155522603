import React from 'react'

import { observer } from 'mobx-react'
import {
  AccordionHeader,
  AccordionIcon,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex
} from '@chakra-ui/core'

import { scrollTo } from 'utils'
import { ICall, callNumberValidationRegex, minBidValidationRegex } from 'models/Call'
import { CallCamera } from 'components/CallCamera'
import { EditableItem } from 'components/EditableItem'
import { isAlive } from 'mobx-state-tree'
import { CallImagesList } from 'components/CallImagesList'
import { FileUploader } from 'components/FileUploader'

export interface ICallListAccordionItemProps {
  auctionID: string
  call: ICall
  defaultIsEditing?: boolean
  isExpanded: boolean
  isLastAdded: boolean
  onHeadllineEdit: (id: string, value: string) => void
  onBidEdit: (id: string, value: number) => void
  onCallNumberEdit: (id: string, value: number) => void
  onCallCommentEdit: (id: string, value: string) => void
  onDeleteCall: (call: ICall) => void
  onClick?: () => void
}

export const CallAccordionItem: React.FC<ICallListAccordionItemProps> = observer(
  ({
    auctionID,
    defaultIsEditing = false,
    call,
    isExpanded,
    isLastAdded,
    onHeadllineEdit,
    onBidEdit,
    onCallNumberEdit,
    onCallCommentEdit,
    onDeleteCall,
    onClick
  }) => {
    const headerRef = React.useRef<any>()
    const cancelRef = React.useRef<any>()

    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = React.useState(false)
    const onCloseDeleteAlert = (): void => setIsDeleteAlertOpen(false)

    const { id, offlineImagesCount, imagesCount } = call
    const imagesToUploadCount = imagesCount - offlineImagesCount

    let uploadStatusClassName
    if (imagesCount === 0) {
      uploadStatusClassName = 'no-images-added'
    } else if (offlineImagesCount === 0) {
      uploadStatusClassName = 'all-images-uploaded'
    } else {
      uploadStatusClassName = 'images-is-uploading'
    }

    React.useEffect(() => {
      call.ListenToImages()

      return (): void => {
        if (isAlive(call)) call.StopListenToImages()
      }
    }, [call])

    React.useEffect(() => {
      if (isLastAdded && headerRef.current) {
        scrollTo(headerRef.current, {
          behavior: 'auto'
        })
      }
    }, [isLastAdded, headerRef])

    return (
      <>
        <AccordionHeader
          ref={headerRef}
          _hover={{ backgroundColor: uploadStatusClassName }}
          backgroundColor={uploadStatusClassName}
          className={`accordion-button no-margin ${uploadStatusClassName}`}
          display="flex"
          flexDir="row"
          justifyContent="space-between"
          width="100%"
          onClick={onClick}
        >
          <Flex alignItems="baseline" className="accordion-button-inner">
            <span>Rop {call.callNumber}.</span>
            <h5 className="no-margin">{call.headline}</h5>
          </Flex>
          <Flex className="upload-counter" marginRight="0">
            {imagesToUploadCount}/{imagesCount}
            <AccordionIcon />
          </Flex>
        </AccordionHeader>
        <AccordionPanel backgroundColor="red" className="accordion-panel">
          <EditableItem
            defaultIsEditing={defaultIsEditing}
            header="Ropnummer"
            validationErrorMessage={'Du måste ange ett giltigt ropnummer'}
            validationPattern={callNumberValidationRegex}
            value={call.callNumber}
            onEditCompleteCallback={(value): void => {
              onCallNumberEdit(id, Number.parseInt(value.toString()))
            }}
          />
          <EditableItem
            defaultIsEditing={defaultIsEditing}
            header="Rubrik"
            value={call.headline}
            onEditCompleteCallback={(value): void => {
              onHeadllineEdit(id, value.toString())
            }}
          />
          <EditableItem
            defaultIsEditing={defaultIsEditing}
            header="Minimumbud"
            inputMode="numeric"
            optional={true}
            validationErrorMessage="Du måste ange ett giltigt minimumbud"
            validationPattern={minBidValidationRegex}
            value={call.minBid}
            onEditCompleteCallback={(value): void => {
              onBidEdit(id, typeof value === 'string' ? Number.parseFloat(value) : value)
            }}
          />
          <EditableItem
            defaultIsEditing={defaultIsEditing}
            header="Kommentar"
            multiline={true}
            value={call.comment}
            onEditCompleteCallback={(value): void => {
              onCallCommentEdit(id, value.toString())
            }}
          />
          {isExpanded && (
            <>
              <CallCamera auctionID={auctionID} call={call} callID={id} />
              <FileUploader accept="image/*" call={call} />
              <CallImagesList call={call} />
            </>
          )}
          <Flex className="remove-call-button-div" flexDir="row">
            <Button
              className="danger btn remove-call-button"
              marginRight="0"
              width="50%"
              onClick={(): void => setIsDeleteAlertOpen(true)}
            >
              Ta bort Rop {call.callNumber}
            </Button>
          </Flex>
        </AccordionPanel>
        <AlertDialog isOpen={isDeleteAlertOpen} leastDestructiveRef={cancelRef} onClose={onCloseDeleteAlert}>
          <AlertDialogOverlay />
          <AlertDialogContent>
            <AlertDialogHeader>Ta bort rop</AlertDialogHeader>
            <AlertDialogBody>
              Är du säker på att du vill ta bort Rop {call.callNumber}: {call.headline}?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                color="red.600"
                marginRight="1rem"
                variant="outline"
                variantColor="red.600"
                onClick={(): void => {
                  onDeleteCall(call)
                }}
              >
                Ta bort
              </Button>
              <Button ref={cancelRef} className="btn primary" onClick={onCloseDeleteAlert}>
                Avbryt
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </>
    )
  }
)
