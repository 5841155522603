import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Input, Spinner } from '@chakra-ui/core'

export type TPhoneNumberInputFormData = {
  phoneNumber: string
}

export interface IPhoneNumberInputProps {
  onSubmit: (data: TPhoneNumberInputFormData) => void
  isLoading: boolean
}

export const PhoneNumberInput: React.FC<IPhoneNumberInputProps> = ({ isLoading, onSubmit }) => {
  const { register, handleSubmit } = useForm<TPhoneNumberInputFormData>()

  if (isLoading) return <Spinner />

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        ref={register}
        autoComplete="tel"
        name="phoneNumber"
        placeholder="Ditt telefonnummer (+4670...)"
        type="tel"
      />
      <Button className="btn special login right" id="login-button" type="submit">
        Nästa
      </Button>
    </form>
  )
}
