import React from 'react'

import { Box, Button, Flex, FormHelperText, FormLabel, Heading, Input, Textarea } from '@chakra-ui/core'
import { useForm } from 'react-hook-form'

import { callNumberValidationRegex, minBidValidationRegex } from 'models/Call'

export interface INewCallImageData {
  blob: Blob
  filename: string
  url: string
  imageTaken: Date
}

export interface INewCallFormProps {
  onAddCall: (header: string, bid: number, number: number, comment: string) => void
  defaultCallNumber: number
}

interface INewCallFormValues {
  header: string
  minBid: number | undefined // undefined is the value when the input is empty
  callNumber: number
  comment: string
}

export const NewCallForm = React.forwardRef<HTMLButtonElement, INewCallFormProps>(
  ({ onAddCall, defaultCallNumber }, ref) => {
    const { register, handleSubmit, getValues, reset, formState, setValue, errors } = useForm<INewCallFormValues>({
      defaultValues: {
        // Don't set callNumber here. We do it with React.useEffect to always get latest defaultCallNumber
        header: '',
        minBid: 0,
        comment: ''
      }
    })

    const headerRef = React.useRef<HTMLElement>()

    const minBidRef = React.useRef<HTMLInputElement>()
    React.useEffect(() => {
      if (minBidRef.current) {
        minBidRef.current.value = ''
      }
    }, [minBidRef])

    React.useEffect(() => {
      if (!formState.touched.callNumber) {
        setValue('callNumber', defaultCallNumber)
      }
    }, [defaultCallNumber, formState.touched.callNumber, setValue])

    const onSubmit = (): void => {
      const { callNumber, comment, header, minBid } = getValues()

      if (typeof minBid === 'undefined') return

      // All validation was OK, add the call
      onAddCall(header, Number.parseInt(minBid.toString()) || 0, Number.parseInt(callNumber.toString()), comment)

      // This fires after React.useEffect(...,[defaultCallNumber]) IF the callNumber has been edited (can be confirmed with console.logs)
      // This is why we have to use both reset with defaultCallNumber AND React.useEffect with defaultCallNumber
      reset({ callNumber: defaultCallNumber, minBid: undefined })
    }

    return (
      <Box bg="#eee" padding="0.5rem 0 1rem  0" textAlign="center" w="100%">
        <form className="new-call-form" onSubmit={handleSubmit(onSubmit)}>
          <Flex className="new-call-form-item" flexDir="column" margin={['0', '1rem']}>
            <FormLabel htmlFor="callNumber" style={{ fontWeight: 'bold' }}>
              Ropnummer
            </FormLabel>
            <Input
              ref={register({ required: true, pattern: callNumberValidationRegex })}
              backgroundColor="white"
              inputMode="numeric"
              name="callNumber"
              type="number"
            />
            {errors.callNumber && (
              <FormHelperText className="new-call-form-error-text">Du måste ange ett giltigt ropnummer</FormHelperText>
            )}
          </Flex>
          <Flex className="new-call-form-item" flexDir="column" margin={['0', '1rem']}>
            <FormLabel htmlFor="header" style={{ fontWeight: 'bold' }}>
              Rubrik
            </FormLabel>
            <Input
              ref={(e: HTMLInputElement): void => {
                register(e, { required: true })
                headerRef.current = e
              }}
              backgroundColor="white"
              name="header"
            />
            {errors.header && (
              <FormHelperText className="new-call-form-error-text" color="tovek.redHue">
                Du måste ange en rubrik
              </FormHelperText>
            )}
          </Flex>
          <Flex className="new-call-form-item" flexDir="column" margin={['0', '1rem']}>
            <FormLabel htmlFor="minBid" style={{ fontWeight: 'bold' }}>
              Minimumbud
            </FormLabel>
            <Input
              ref={(element: HTMLInputElement): void => {
                minBidRef.current = element
                register(element, { required: false, pattern: minBidValidationRegex, minLength: 1 })
              }}
              backgroundColor="white"
              name="minBid"
              type="number"
            />
            {errors.minBid && (
              <FormHelperText className="new-call-form-error-text" color="tovek.redHue">
                Du måste ange ett giltigt minimumbud (endast siffror)
              </FormHelperText>
            )}
          </Flex>
          <Flex className="new-call-form-item" flexDir="column" margin={['0', '1rem']}>
            <FormLabel htmlFor="comment" style={{ fontWeight: 'bold' }}>
              Kommentar
            </FormLabel>
            <Textarea
              ref={register({ required: false })}
              backgroundColor="white"
              display="flex"
              name="comment"
              padding="0.5rem 0.25rem"
            />
            {errors.comment && (
              <FormHelperText className="new-call-form-error-text" color="tovek.redHue">
                Ogiltig kommentar
              </FormHelperText>
            )}
          </Flex>

          <Button
            ref={ref}
            className="btn primary"
            style={{ position: 'fixed', right: '50%', left: '50%', bottom: 0, marginLeft: '-150px', zIndex: 999 }}
            type="submit"
            width="300px"
          >
            Spara/Lägg till
          </Button>
        </form>
      </Box>
    )
  }
)
