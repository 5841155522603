import React from 'react'
import { observer } from 'mobx-react'
import { Alert, Box, Button, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/core'
import { Redirect, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

import { useStores } from '../models'
import { TVerificationInputFormData, VerificationInput } from 'components/VerificationCodeInput'
import { PhoneNumberInput, TPhoneNumberInputFormData } from 'components/PhoneNumberInput'
import { EmailLoginForm, TEmailLoginFormData } from 'components/EmailLoginForm'
import { ResetEmailPasswordForm, TResetEmailPasswordFormData } from 'components/ResetEmailPasswordForm'
import { Page } from './Page'

declare global {
  // eslint-disable-next-line @typescript-eslint/interface-name-prefix
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    recaptchaVerifier: any
  }
}

export const LoginPage: React.FC<{}> = observer(() => {
  const { User } = useStores()
  const { loginErrorMessage } = User

  React.useEffect(() => {
    firebase.auth().useDeviceLanguage()
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('captcha-div', {
      size: 'invisible',
      callback: function (response: unknown): void {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        if (response) {
          // Do something here? Like set a flag to allow signIn?
        }
      }
    })
  }, [])

  const [userErrorMessage, setUserErrorMessage] = React.useState('')
  const [userMessage, setUserMessage] = React.useState('')

  const [isSendingSms, setIsSendingSms] = React.useState(false)

  const [showVerificationCode, setShowVerificationCode] = React.useState(false)
  const [isSigningIn, setIsSigningIn] = React.useState(false)

  const [confirmationResult, setConfirmationResult] = React.useState<firebase.auth.ConfirmationResult | undefined>(
    undefined
  )

  const [displayResetPassword, setDisplayResetPassword] = React.useState(false)

  const onPhoneNumberCompleteClick = async (phoneData: TPhoneNumberInputFormData): Promise<void> => {
    try {
      setUserErrorMessage('')
      setIsSendingSms(true)
      setConfirmationResult(
        await firebase.auth().signInWithPhoneNumber(phoneData.phoneNumber, window.recaptchaVerifier)
      )

      setShowVerificationCode(true)
    } catch (e) {
      Sentry.captureException(e)
      let errorMessage: string
      if (typeof e === 'object' && e !== null && 'toString' in e) {
        errorMessage = e.toString()
      } else {
        errorMessage = String(e)
      }
      setUserErrorMessage(errorMessage)
    } finally {
      setIsSendingSms(false)
    }
  }

  const onConfirmationClick = async (data: TVerificationInputFormData): Promise<void> => {
    try {
      setIsSigningIn(true)

      const { verificationCode } = data

      if (!!confirmationResult?.verificationId) {
        const credential = firebase.auth.PhoneAuthProvider.credential(
          confirmationResult.verificationId,
          verificationCode
        )

        await firebase.auth().signInWithCredential(credential)
      } else {
        await confirmationResult?.confirm(verificationCode)
      }
    } catch (e) {
      Sentry.captureException(e)
      let errorMessage: string
      if (typeof e === 'object' && e !== null && 'toString' in e) {
        errorMessage = e.toString()
      } else {
        errorMessage = String(e)
      }
      setUserErrorMessage(errorMessage)
    } finally {
      setShowVerificationCode(false)
      setIsSigningIn(false)
    }
  }

  const onEmailLoginClick = async ({ email, password }: TEmailLoginFormData) => {
    try {
      setIsSigningIn(true)
      const uc = await firebase.auth().signInWithEmailAndPassword(email, password)
    } catch (e) {
      Sentry.captureException(e)

      if (typeof e === 'string') {
        setUserErrorMessage(e)
      } else if (typeof e === 'object' && e !== null && 'toString' in e) {
        setUserErrorMessage(e.toString())
      } else {
        setUserErrorMessage('Ett oväntat fel uppstod')
      }
    } finally {
      setIsSigningIn(false)
    }
  }

  const onEmailResetClick = async ({ email }: TResetEmailPasswordFormData) => {
    try {
      setIsSigningIn(true)
      await firebase.auth().sendPasswordResetEmail(email)

      setUserMessage('Återställningsmail skickat!')
    } catch (e) {
      Sentry.captureException(e)
      if (typeof e === 'string') {
        setUserErrorMessage(e)
      } else if (typeof e === 'object' && e !== null && 'toString' in e) {
        setUserErrorMessage(e.toString())
      } else {
        setUserErrorMessage('Ett oväntat fel uppstod')
      }
    } finally {
      setIsSigningIn(false)
    }
  }

  const location = useLocation<{ from: string }>()
  const { from } = location.state || { from: { pathname: '/' } }

  return (
    <Page>
      {User.isSignedIn && !isSigningIn && <Redirect to={from} />}
      <div id="captcha-div" />
      {User.isInitating ? (
        <Spinner />
      ) : (
        <Box>
          <Alert marginTop="1rem">
            <p>
              <b>Important:</b> Offline persistence is supported only by the Chrome, Safari, and Firefox web browsers.
            </p>
          </Alert>
          <h1>Logga in</h1>
          <Tabs isFitted variant="enclosed">
            <TabList mb="1em">
              <Tab>SMS</Tab>
              <Tab>Epost</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {process.env.NODE_ENV === 'development' && (
                  <Box>
                    <p>
                      Testnummer: <span>+1 650-555-3434</span>
                    </p>
                  </Box>
                )}

                {!showVerificationCode && (
                  <PhoneNumberInput isLoading={isSendingSms} onSubmit={onPhoneNumberCompleteClick} />
                )}
                {showVerificationCode && <VerificationInput isLoading={isSigningIn} onSubmit={onConfirmationClick} />}
              </TabPanel>
              <TabPanel>
                {displayResetPassword ? (
                  <>
                    <ResetEmailPasswordForm onSubmit={onEmailResetClick} />
                    <Button
                      onClick={(): void => {
                        setDisplayResetPassword(false)
                      }}
                    >
                      Logga in istället
                    </Button>
                  </>
                ) : (
                  <>
                    <EmailLoginForm disabled={isSigningIn} onLogin={onEmailLoginClick} />
                    <Button
                      onClick={(): void => {
                        setDisplayResetPassword(true)
                      }}
                    >
                      Glömt lösenordet?
                    </Button>
                  </>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
          <Box>{!!userMessage && <Alert status="success">{userMessage}</Alert>}</Box>
          <Box>{!!userErrorMessage && <Alert status="error">{userErrorMessage}</Alert>}</Box>
          <Box>{!!loginErrorMessage && <Alert status="error">{loginErrorMessage}</Alert>}</Box>
          <p>
            <a href="https://firebase.google.com/docs/auth/web/phone-auth" rel="noopener noreferrer" target="_blank">
              Terms apply
            </a>
          </p>
        </Box>
      )}
    </Page>
  )
})
