import React from 'react'
import { Link } from 'react-router-dom'
import { Flex } from '@chakra-ui/core'

export const NotFoundPage: React.FC<{}> = () => (
  <Flex>
    <h1>404</h1>
    <Link to="/">
      <p>Go back</p>
    </Link>
  </Flex>
)
