import * as React from 'react'

import { Alert, Icon, Text } from '@chakra-ui/core'
import { observer } from 'mobx-react'

import { useStores } from 'models'

export const OfflineIndicatorAlert: React.FC = observer(() => {
  const {
    AppConfig: { isOffline }
  } = useStores()

  if (isOffline)
    return (
      <Alert className="alert" id="offline" marginBottom="0" marginTop="0" status="warning">
        <Icon marginRight="1rem" name="warning-2" size="25px" />
        <Text marginBottom="0" marginTop="0">
          Ingen internetuppkoppling
        </Text>
        <Text marginBottom="0" marginTop="0">
          Ändringar och tillägg skickas när du har tillgång till internet.
        </Text>
      </Alert>
    )

  return <></>
})
