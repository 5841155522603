import { Instance, SnapshotIn, getSnapshot, types } from 'mobx-state-tree'

import firebase from 'firebase/app'
import 'firebase/firestore'

import { Call, ICall } from './Call'
import { INewCallImageData } from 'components/NewCallForm'
import { getSmallestPositiveMissingNumber } from 'utils'
import { getCallsFirestorePath } from 'utils/calls'

export type TImageToAddData = { id: string; data: INewCallImageData }

export const Auction = types
  .model({
    id: types.identifier,
    name: types.string,
    calls: types.map(Call),
    isFinished: types.maybeNull(types.boolean),
    allowUserIDs: types.array(types.string),
    creator: types.string
  })
  .views((self) => ({
    get totalImagesCount(): number {
      return Array.from(self.calls.values()).reduce(
        (previousCount, call): number => previousCount + call.imagesCount,
        0
      )
    },
    get callsSorted(): ICall[] {
      return Array.from(self.calls.values()).sort((a, b) => b.callNumber - a.callNumber)
    }
  }))
  .views((self) => ({
    get imagesToBeUploadedCount(): number {
      const offlineImagesCount = Array.from(self.calls.values()).reduce(
        (previousCount, call) => previousCount + call.offlineImagesCount,
        0
      )
      return self.totalImagesCount - offlineImagesCount
    },
    get hasMissingCallNumbers(): boolean {
      const nextNumber =
        getSmallestPositiveMissingNumber(
          Array.from(self.calls).map(([, call]) => call.callNumber),
          true
        ) - 1

      console.log('Auction.hasMissingCallNumbers: nextNumber =', nextNumber, 'self.calls.size =', self.calls.size)

      return nextNumber !== self.calls.size
    }
  }))
  .actions((self) => ({
    SetName: (value: string): void => {
      self.name = value
    },
    SetIsFinished: (value: boolean): void => {
      self.isFinished = value
    },
    SetCreator: (value: string): void => {
      self.creator = value
    },
    SetAllowUserIDs: (value: string[]): void => {
      self.allowUserIDs.replace(value)
    }
  }))
  .extend((self) => ({
    actions: {
      _setCalls: (map: { [key: string]: ICall }): void => {
        self.calls.replace(map)
      },
      _onCallSnapshot: (
        callID: string,
        data: Exclude<ICall, 'id' | 'auctionID'>
      ): Instance<typeof Call> | undefined => {
        let call = self.calls.get(callID)
        if (call) {
          call.SetCallNumber(data.callNumber)
          call.SetComment(data.comment)
          call.SetHeadline(data.headline)
          call.SetMinBid(data.minBid)
        } else {
          self.calls.set(callID, {
            ...data,
            id: callID,
            auctionID: self.id
          })
          call = self.calls.get(callID)
        }

        return call
      },
      _deleteCall: (callID: string): void => {
        if (self.calls.has(callID)) self.calls.delete(callID)
      }
    }
  }))
  .extend((self) => {
    const listenMap = new Map<string, () => void>()

    return {
      actions: {
        /**
         * Returns the id of the newly created call
         */
        AddCall: (callData: Omit<SnapshotIn<typeof Call>, 'id' | 'auctionID'>): string => {
          const collRef = firebase.firestore().collection(getCallsFirestorePath(self.id))

          const docRef = collRef.doc()

          docRef.set({ auctionID: self.id, ...callData })

          return docRef.id
        },
        UpdateCall: (callID: string, callData: Partial<SnapshotIn<typeof Call>>): void => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { id, images, ...data } = callData // Deconstruct props we don't want to upload to firebase

          const call = self.calls.get(callID)

          if (call) {
            self.calls.set(callID, Object.assign({}, getSnapshot(call), data))
          }

          firebase.firestore().doc(getCallsFirestorePath(self.id, callID)).update(data)
        },
        StopListenToCall: (callID: string): void => {
          const unsub = listenMap.get(callID)

          if (!!unsub) {
            unsub()
            listenMap.delete(callID)
          }
        },
        RemoveCall: (callID: string): void => {
          firebase.firestore().doc(getCallsFirestorePath(self.id, callID)).delete()
        }
      }
    }
  })
  .actions((self) => {
    let unsub: undefined | (() => void)

    return {
      ListenToAuctionCalls: (onNewCall?: (call: Instance<typeof Call>) => void): void => {
        if (!!unsub) return

        unsub = firebase
          .firestore()
          .collection(`/auctions/${self.id}/calls`)
          .onSnapshot((snapshot) => {
            if (Array.from(self.calls.keys()).length === 0) {
              const map: { [key: string]: ICall } = {}
              snapshot.docs.forEach(
                (doc) =>
                  (map[doc.id] = {
                    ...(doc.data() as ICall),
                    id: doc.id,
                    auctionID: self.id
                  })
              )

              self._setCalls(map)
            }

            snapshot.docChanges().forEach(({ doc, type }) => {
              if (type === 'removed') {
                self.StopListenToCall(doc.id)
                self._deleteCall(doc.id)
                return
              }

              const call = self._onCallSnapshot(doc.id, {
                ...(doc.data() as ICall),
                id: doc.id,
                auctionID: self.id
              })

              if (!!onNewCall && !!call) {
                onNewCall(call)
              }
            })
          })
      },
      StopListenToAuctionCalls: (): void => {
        if (unsub) {
          self.calls.forEach((call) => {
            self.StopListenToCall(call.id)
          })

          unsub()
          unsub = undefined
        }
      }
    }
  })

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAuction extends Instance<typeof Auction> {}
