import React from 'react'
import { observer } from 'mobx-react'
import { Link, useParams } from 'react-router-dom'
import { Button, Heading, Icon, IconButton, Input, Text } from '@chakra-ui/core'

import { useStores } from 'models'
import { getSmallestPositiveMissingNumber } from 'utils'
import { Page } from './Page'
import { CallsAccordion } from 'components/CallsAccordion'
import { NewCallForm } from 'components/NewCallForm'

export const CallsPage: React.FC = observer(() => {
  const store = useStores()

  const { auctionID } = useParams<{ auctionID?: string }>()
  const auction = store.User.Auctions.get(auctionID || '')

  if (!auctionID || !auction) {
    // TODO: Start listening to auction and if we then can't find it, show this
    return (
      <Page>
        <p>Kunde inte hitta den här auktionen!</p>
        <Link to="/">Tillbaka</Link>
      </Page>
    )
  }

  const [latestAddedCallID, setLatestAddedCallID] = React.useState<string | undefined>()

  React.useEffect(() => {
    store.User.ListenToAuction(auction.id) // Är inte denna onödig då vi har en return ovan?
    auction.ListenToAuctionCalls()

    return (): void => {
      auction.StopListenToAuctionCalls()
      store.AppConfig.CloseCameraSteam()
    }
  }, [auction, store.User, store.AppConfig])

  const getSmallestCallNumber = (): number =>
    getSmallestPositiveMissingNumber(
      Array.from(auction.calls).map(([, call]) => call.callNumber),
      true
    )

  const [isEditingAuctionHeader, setIsEditinAuctionHeader] = React.useState(false)
  const [headerEditValue, setHeaderEditValue] = React.useState(auction.name)

  const onAuctionHeaderEdit = (): void => {
    store.User.UpdateAuction(auction.id, { name: headerEditValue })
  }

  const onAddCall = (header: string, bid: number, callNumber: number, comment: string): void => {
    const id = auction.AddCall({
      headline: header,
      minBid: bid,
      callNumber,
      comment: comment
    })

    setLatestAddedCallID(id)
  }

  const formSubmitButtonRef = React.createRef<HTMLButtonElement>()
  const onPlusButtonClick = (): void => {
    if (!formSubmitButtonRef.current) return

    formSubmitButtonRef.current.click()
  }

  return (
    <Page
      headerButton={
        <Button className="add-call-plus-button" onClick={onPlusButtonClick}>
          {''}
        </Button>
      }
      headerIcon={
        auction.hasMissingCallNumbers ? <Icon color="#fedc32" fontSize="1.75em" name="warning-2" /> : undefined
      }
    >
      <Heading className="auction-title" margin="1em 0 0.5em 0">
        {isEditingAuctionHeader ? (
          <Input
            value={headerEditValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              setHeaderEditValue(event.target.value)
            }}
          />
        ) : (
          <span className="auction-title">{auction.name} </span>
        )}
        <IconButton
          aria-label="Edit Auction Name"
          className="transparent icon"
          icon={isEditingAuctionHeader ? 'check' : 'edit'}
          onClick={(): void => {
            if (isEditingAuctionHeader) {
              onAuctionHeaderEdit()
            } else {
              setHeaderEditValue(auction.name)
            }

            setIsEditinAuctionHeader(!isEditingAuctionHeader)
          }}
        />
      </Heading>
      <Text position="absolute" right="1em" top="0">
        ({auction.imagesToBeUploadedCount}/{auction.totalImagesCount} Bilder uppladdade)
      </Text>

      <NewCallForm ref={formSubmitButtonRef} defaultCallNumber={getSmallestCallNumber()} onAddCall={onAddCall} />
      <CallsAccordion auction={auction} latestAddedCallID={latestAddedCallID} />
    </Page>
  )
})
