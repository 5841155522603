import { assign, createMachine, send } from 'xstate'

import { getCameraStream } from 'utils/Camera'

import { ICameraMeasurements } from './Camera.interfaces'

export interface ICameraContext {
  measurements?: ICameraMeasurements
  stream?: MediaStream
  errorMessage?: string
}

export type TCameraEvent =
  | { type: 'START' }
  | {
      type: 'CAPTURE'
      measurements: ICameraMeasurements
    }
  | {
      type: 'SETUP_ERROR'
      errorMessage: string
    }
  | {
      type: 'SET_CAMERA_MEASUREMENTS'
      measurements: ICameraMeasurements
    }
  | {
      type: 'CAPTURE_ERROR'
      errorMessage: string
    }
  | {
      type: 'CAPTURE_COMPLETE'
    }

export type CameraTypestate =
  | {
      value: 'off'
      context: ICameraContext & {
        measurements: undefined
      }
    }
  | {
      value: 'initializing'
      context: ICameraContext & {
        measurements: undefined
      }
    }
  | {
      value: 'error'
      context: ICameraContext & {
        measurements: undefined
      }
    }
  | {
      value: 'idle'
      context: ICameraContext & {
        stream: MediaStream
        measurements: ICameraMeasurements
      }
    }
  | {
      value: 'capturing'
      context: ICameraContext & {
        measurements: ICameraMeasurements
      }
    }

export const cameraMachine = createMachine<ICameraContext, TCameraEvent, CameraTypestate>({
  id: 'camera',
  initial: 'off',
  context: {
    measurements: undefined,
    stream: undefined,
    errorMessage: undefined
  },
  states: {
    off: {
      on: {
        START: 'initializing'
      },
      entry: assign({
        stream: (context) => {
          const { stream } = context

          if (stream === undefined) return undefined

          stream.getTracks().forEach((track) => track.stop())

          return undefined
        }
      })
    },
    initializing: {
      id: 'initializing',
      initial: 'getCameraStream',
      states: {
        getCameraStream: {
          invoke: {
            id: 'getCameraStream',
            src: getCameraStream,
            onError: {
              actions: send({
                type: 'SETUP_ERROR',
                errorMessage: 'Tyvärr uppstod ett fel när kameran skulle öppnas'
              })
            },
            onDone: {
              target: 'setMeasurements',
              actions: assign({
                stream: (_, event) => event.data
              })
            }
          }
        },
        setMeasurements: {
          on: {
            SET_CAMERA_MEASUREMENTS: {
              target: 'ready',
              actions: assign({
                measurements: (_, event) => event.measurements
              })
            }
          }
        },
        ready: {
          type: 'final'
        }
      },
      on: {
        SETUP_ERROR: 'error'
      },
      onDone: 'idle'
    },
    error: {
      entry: assign({
        errorMessage: (_, event) => ('errorMessage' in event ? event.errorMessage : undefined)
      }),
      exit: assign<ICameraContext>({
        errorMessage: undefined
      })
    },
    idle: {
      on: {
        SETUP_ERROR: 'error',
        CAPTURE: 'capturing'
      }
    },
    capturing: {
      on: {
        CAPTURE_ERROR: 'error',
        CAPTURE_COMPLETE: 'idle'
      }
    }
  }
})
