import React from 'react'
import { Box, Button, Divider, Heading, Icon, List, ListItem, Text } from '@chakra-ui/core'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'

import { IAuction } from 'models/Auction'
import { useStores } from 'models'
import { IUserData } from 'models/UserStore'

export interface IAuctionsListItemProps {
  auction: IAuction
  onFinishAuction: (auctionID: string) => void
}

export const AuctionsListItem: React.FC<IAuctionsListItemProps> = observer(({ auction, onFinishAuction }) => {
  const store = useStores()

  const [showShareThing, setShowShareThing] = React.useState(false)

  const onFinishClick = (): void => {
    onFinishAuction(auction.id)
  }

  const onShareAuction = (user: IUserData): void => {
    if (auction.allowUserIDs.includes(user.uid)) {
      user.RemoveSharedAuction(auction)
    } else {
      user.AddSharedAuction(auction)
    }
  }

  return (
    <ListItem
      alignItems="center"
      bg={auction.isFinished ? '#eee' : 'white'}
      className="auctionLink"
      // alignItems="center"
      // bg={auction.isFinished ? 'tovek.auctions.finished.background' : undefined}
      // className="auctionLink"
      // display="flex"
      // flexWrap="wrap"
    >
      <Box width="100%">
        <Link style={{ color: '#1b458c', fontWeight: 600, margin: 0, fontSize: '0.75em' }} to={`calls/${auction.id}`}>
          <Heading as="h3" fontSize="1.25em" fontWeight="600" margin="0">
            {auction.name}
          </Heading>
        </Link>
      </Box>
      <Box display="grid" gridGap="1rem" gridTemplateColumns="3rem 3rem" marginRight="0" width="100%">
        <Button
          className={`finish-btn btn no-margin ${
            auction.isFinished ? 'finish-btn-completed' : 'finish-btn-uncompleted'
          }`}
          // gridColumn="1/2"
          onClick={onFinishClick}
        >
          {''}
        </Button>
        <Button
          className="share-btn btn no-margin"
          // gridColumn="1/3"
          onClick={(): void => setShowShareThing(!showShareThing)}
        >
          {''}
        </Button>
      </Box>
      {showShareThing && (
        <List width="100%">
          {store.User.Colleagues.map((colleague) => {
            const isShared = auction.allowUserIDs.includes(colleague.uid)
            return (
              <ListItem key={colleague.uid} className="colleague-card" marginTop="1rem">
                <Box display="flex" flexWrap="wrap">
                  <Text flexGrow={1}>
                    {colleague.firstName} {colleague.lastName}
                  </Text>
                  <Box display="flex" flexGrow={1} justifyContent="flex-end">
                    <Button
                      className={`secondary ${isShared ? 'is-shared-btn' : 'share-btn'}`}
                      onClick={(): void => {
                        onShareAuction(colleague)
                      }}
                    >
                      {''}
                    </Button>
                  </Box>
                </Box>
                <Divider />
              </ListItem>
            )
          })}
        </List>
      )}
    </ListItem>
  )
})
