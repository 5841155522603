import React from 'react'

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  IconButton,
  Image,
  Progress,
  Spinner
} from '@chakra-ui/core'
// import ReactImageAppear from 'react-image-appear'
import { observer } from 'mobx-react'

import { ICall } from 'models/Call'
import { IImage } from 'models/Images'

import { IMAGE_DIMENSIONS } from 'config/Images'

import placeholderImage from './1080.png'

export const CameraImage: React.FC<{ call: ICall; image: IImage }> = observer(({ call, image }) => {
  const { filename, url } = image

  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false)
  const onCloseDeleteConfirmationAlert = (): void => setDeleteConfirmationOpen(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cancelRef = React.useRef<any>()

  const [isImageLoaded, setIsImageLoaded] = React.useState(false)

  const onDeleteImage = (): void => {
    setDeleteConfirmationOpen(false)
    call.RemoveImage(filename)
  }

  return (
    <Box position="relative">
      {/* {!image.isUploaded && <Progress hasStripe isAnimated value={image.uploadProgress * 100} />} */}
      {!isImageLoaded && (
        <Image
          display="block"
          height="auto"
          maxHeight={IMAGE_DIMENSIONS.height}
          maxWidth={IMAGE_DIMENSIONS.width}
          src={placeholderImage}
          width="100%"
        />
      )}
      <Image
        display="block"
        height="auto"
        hidden={!isImageLoaded}
        maxHeight={IMAGE_DIMENSIONS.height}
        maxWidth={IMAGE_DIMENSIONS.width}
        src={url}
        width="100%"
        onLoad={(): void => {
          setIsImageLoaded(true)
        }}
      />
      {!image.isUploaded && (
        <Box left="1rem" position="absolute" top="1rem">
          <span style={{ color: 'white', background: 'black' }}>Laddar upp...</span>
          {/* <Spinner /> */}
        </Box>
      )}
      {isImageLoaded && (
        <IconButton
          aria-label="Ta bort bild"
          // color="red.600"
          height="50px"
          icon={'delete'}
          position="absolute"
          right="1rem"
          size="lg"
          top="1rem"
          variant="solid"
          w="50px"
          // variantColor="red.600"
          onClick={(): void => setDeleteConfirmationOpen(true)}
        />
      )}
      <AlertDialog
        isOpen={isDeleteConfirmationOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseDeleteConfirmationAlert}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Ta bort bild</AlertDialogHeader>
          <AlertDialogBody>
            <p>Är du säker på att du vill ta bort den här bilden?</p>
            <Image
              display="block"
              height="auto"
              maxHeight={IMAGE_DIMENSIONS.height}
              maxWidth={IMAGE_DIMENSIONS.width}
              src={url}
              width="100%"
            />
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button color="red.600" marginRight="1rem" variant="outline" variantColor="red.600" onClick={onDeleteImage}>
              Ta bort
            </Button>
            <Button ref={cancelRef} className="btn primary" onClick={onCloseDeleteConfirmationAlert}>
              Avbryt
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Box>
  )
})
