export const waitFor = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

/**
 * Finds the smallest number which is not present in the array.
 * @param array
 * @param useOneIndex Defaults to using 0-index, therefore false
 *
 * @example
 * // Returns 3
 * getSmallestMissingNumber([0, 1, 2, 4, 5])
 * // Returns 3
 * getSmallestMissingNumber([0, 1, 2, 4, 5], true)
 * @example
 * // Returns 0
 * getSmallestMissingNumber([1, 2, 3])
 * // Returns 4
 * getSmallestMissingNumber([1, 2, 3], true)
 * @example
 * // Returns 0
 * getSmallestMissingNumber([])
 * // Returns 1
 * getSmallestMissingNumber([], true)
 * @example
 * // Returns 3
 * getSmallestMissingNumber([0, 4, 2, 7, 5, 1])
 * // Returns 3
 * getSmallestMissingNumber([0, 4, 2, 7, 5, 1], true)
 */
export const getSmallestPositiveMissingNumber = (array: number[], useOneIndex = false): number => {
  let smallest = useOneIndex ? 1 : 0
  const seen: number[] = []

  array.forEach((item) => {
    seen.push(item)
  })

  seen.sort((a, b) => a - b)

  for (let i = 0; i < seen.length; i++) {
    if (smallest === seen[i]) {
      smallest++
    } else if (smallest < seen[i]) {
      break
    }
  }

  return smallest
}

export const formatValueToNumber = (value: string | number): number => {
  let newValue = value.toString()
  const regexp = new RegExp(/\s/)
  while (regexp.test(newValue)) {
    newValue = newValue.replace(regexp, '')
  }

  newValue = newValue.replace(',', '.')

  return Number.parseFloat(newValue)
}

/** Source: https://emailregex.com/ */
export const EmailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

export * from './LocalStorageCircularArray'
export * from './AddBrowserCameraSupport'
export * from './Camera'

/** Scroll To */
export interface IScrollToOptions {
  /**
   * Specifies the number of pixels along the X axis to scroll the window or element.
   */
  xOffset?: number

  /**
   * Specifies the number of pixels along the Y axis to scroll the window or element.
   */
  yOffset?: number

  /**
   * Specifies whether the scrolling should animate smoothly, or happen instantly in a single jump. This is actually defined on the ScrollOptions dictionary, which is implemented by ScrollToOptions.
   *
   * Defaults to `smooth`
   */
  behavior?: ScrollBehavior
}

export function scrollTo(target: HTMLElement, options?: IScrollToOptions): void {
  const defaultOptions: Required<IScrollToOptions> = {
    behavior: 'smooth',
    xOffset: 0,
    yOffset: 0
  }

  const { offsetLeft, offsetTop } = target

  /** Specifies the number of pixels along the X axis to scroll the window or element. */
  const xTarget = offsetLeft
  /** Specifies the number of pixels along the Y axis to scroll the window or element. */
  const yTarget = offsetTop

  const { xOffset, yOffset, behavior }: Required<IScrollToOptions> = {
    ...defaultOptions,
    ...options
  }
  const left = xTarget + xOffset
  const top = yTarget + (yOffset || 0)

  window.scrollTo({ left, top, behavior })
}
