import React from 'react'

import { Flex } from '@chakra-ui/core'
import { useObserver } from 'mobx-react'

import { ICall } from 'models/Call'

import { Camera } from 'components/Camera'

import { createUniquieImageName } from 'utils'

interface ICallCameraProps {
  call: ICall
  callID: string
  auctionID: string
}

export const CallCamera: React.FC<ICallCameraProps> = React.memo(function CallCamera({ call }) {
  const onImageTaken = async (imageData: Blob, fileFormat: string): Promise<void> => {
    const filename = createUniquieImageName(fileFormat)

    console.log('adding image', filename)
    call.AddImage(filename, imageData)
  }

  return useObserver(() => (
    <Flex flexDirection="column" position="relative">
      <Camera onImageTaken={onImageTaken} />
    </Flex>
  ))
})
