/* eslint-disable @typescript-eslint/camelcase */
import { observable } from 'mobx'
import { Instance, SnapshotIn, flow, getParentOfType, getSnapshot, types } from 'mobx-state-tree'
import * as Sentry from '@sentry/browser'

import firebase from 'firebase/app'
import 'firebase/storage'
import { Call } from './Call'

export const Image = types
  .model({
    id: types.identifier,
    callID: types.string,
    filename: types.string,
    url: types.maybe(types.string),
    imageTaken: types.frozen<firebase.firestore.Timestamp>(),
    storagePath: types.string
  })
  .extend((self) => {
    const isUploaded = observable.box<boolean>(!!self.url && !self.url.includes(window.location.hostname))

    const _checkUploadStatus = async () => {
      if (!self.url) return

      try {
        const metadata = await firebase.storage().ref(self.storagePath).getMetadata()

        if (metadata && metadata.type === 'file') {
          isUploaded.set(true)
        } else {
        }
      } catch (error) {
        // File not found
      }
    }

    return {
      actions: {
        SetUrl: (url: string): void => {
          self.url = url
        },
        _checkUploadStatus
      },
      views: {
        get isUploaded(): boolean {
          return isUploaded.get()
        }
      }
    }
  })
  .actions((self) => {
    const isUploading = observable.box<boolean>(false)

    const _upload = async (ref: firebase.storage.Reference, imageData: Blob): Promise<void> => {
      try {
        const task = ref.put(imageData)

        // task.on(firebase.storage.TaskEvent.STATE_CHANGED, ({ bytesTransferred, totalBytes }) => {
        //   self.SetUploadProgress(bytesTransferred / totalBytes)
        // })

        const result = await task

        if (result.state === firebase.storage.TaskState.SUCCESS) {
          const url = await ref.getDownloadURL()
          if (url) {
            try {
              const parentCall = getParentOfType(self, Call)

              await self._checkUploadStatus()
              if (!self.isUploaded) {
                // Image is missing in storage?!
                throw new Error('No image in storage!')
              }

              // TODO: When replacing the URL, if it's a local reference, destroy it (see also call.ts: _onAddImage)
              // If we don't do delete it, it's a memory leak: https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL#memory_management
              self.SetUrl(url)

              const updateSnapshot = getSnapshot(self)

              // parentCall.UpdateImageData(self.id, updateSnapshot)
              parentCall._updateImageFirestoreDoc(self.id, updateSnapshot)
            } catch (err) {
              Sentry.captureException(err)
            }
          } else throw new Error('No url')
        } else {
          throw new Error('put-task did not finish with TaskState "Success": ' + result.state)
        }
      } catch (e) {
        Sentry.captureException(e)

        // Try again in between 2 and 7 minutes
        return await new Promise((resolve) => {
          async function _uploadErrorCallback(): Promise<void> {
            await _upload(ref, imageData)
            resolve()
          }

          setTimeout(_uploadErrorCallback, (2 + Math.random() * 5) * 60 * 1000)
        })
      }
    }

    return {
      Upload: flow(function* upload(imageData: Blob, firebaseStoragePath: string): any {
        const willAbort = self.isUploaded || isUploading.get()
        if (willAbort) return
        isUploading.set(true)

        try {
          const ref = firebase.storage().ref(firebaseStoragePath)

          yield _upload(ref, imageData)
        } catch (e) {
          console.error(e)
          Sentry.captureException(e)
        } finally {
          isUploading.set(false)
        }
      })
    }
  })

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IImage extends Instance<typeof Image> {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IImageData extends SnapshotIn<typeof Image> {}
