import React from 'react'

import { CSSReset, ThemeProvider } from '@chakra-ui/core'
import { onSnapshot } from 'mobx-state-tree'
import * as Sentry from '@sentry/browser'

import './App.css'
import * as serviceWorker from '../serviceWorker'
import { RootStoreProvider } from 'models'
import { RootStore } from 'models/RootStore'

import { AppRouter } from '../pages/AppRouter'
import { createEnvironment, setupRootStore } from './setupRootStore'
import { TRootStore } from 'models/RootStore'
import { tovekTheme } from './TovekTheme'

export const App = (): JSX.Element => {
  // Why use an array for snapshots?
  // Might be good for error-handling, we get a full history of what happened in the state
  // As well as a good practice to generalize something we might use in other projects
  // Idea: Add another circular array for patches to get even a better view of what happened
  // const offlineHistory = new LocalStorageCircularArray<SnapshotOut<TRootStore>>(
  //   OFFLINE_STATE_KEY,
  //   OFFLINE_STATE_HISTORY_SIZE
  // )

  // const latest = offlineHistory.getLatest()

  const latestKey = 'latest-mobx'
  const latest = localStorage.getItem(latestKey)

  const env = createEnvironment()

  let rootStore: TRootStore
  try {
    if (latest) {
      const snapshot = JSON.parse(latest)
      rootStore = RootStore.create(snapshot, env)
    } else rootStore = setupRootStore(env)
  } catch (e) {
    Sentry.captureException(e)
    rootStore = setupRootStore(env)
  }

  onSnapshot(rootStore, (snapshot) => {
    // offlineHistory.push(snapshot)
    localStorage.setItem(latestKey, JSON.stringify(snapshot))
  })

  serviceWorker.register({
    onUpdate: (registration) => {
      rootStore.AppConfig.AddNewAvailableServiceWorker(registration)
    },
    onSuccess: () => {
      rootStore.AppConfig.ServiceWorkerAdded()
    }
  })

  // if (latest) {
  //   try {
  //     const snapshot = JSON.parse(latest)
  //     console.log('applying saved snapshot with Auctions', snapshot.User.Auctions)
  //     console.log('rootStore', rootStore)
  //     applySnapshot(rootStore, snapshot)
  //   } catch (e) {
  //     Sentry.captureException(e)
  //   }
  // }

  return (
    <RootStoreProvider value={rootStore}>
      <ThemeProvider theme={tovekTheme}>
        <CSSReset />
        <AppRouter />
      </ThemeProvider>
    </RootStoreProvider>
  )
}
