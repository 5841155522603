import React from 'react'
import { Box, Flex, Image } from '@chakra-ui/core'
import { Link } from 'react-router-dom'

import { OfflineIndicatorAlert } from 'components/OfflineIndicatorAlert'

import tovekLogo from './app-logo.png'

export interface ITovekHeaderProps {
  headerButton?: JSX.Element
  headerIcon?: JSX.Element
}

export const TovekHeader = ({ headerButton, headerIcon }: ITovekHeaderProps): React.ReactElement => (
  <>
    <Box
      bg="tovek.blueHue"
      className="tovek-header"
      display="flex"
      justifyContent="space-between"
      justifyItems="self-end"
      left="0"
      top="0"
    >
      <Link style={{ color: 'white', textDecoration: 'none', height: '3em' /* See App.css: .tovek-header */ }} to="/">
        <Image alt="Toveks logo" height="100%" src={tovekLogo} />
      </Link>
      {!!headerIcon && <Flex alignItems="center">{headerIcon}</Flex>}
      <Flex alignItems="center">{headerButton}</Flex>
    </Box>
    <OfflineIndicatorAlert />
  </>
)
