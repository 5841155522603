import * as React from 'react'

import { Alert, Box, Button, Icon, Spinner, Text, useToast } from '@chakra-ui/core'
import { observer } from 'mobx-react'

import { useStores } from 'models'

export const SWUpdateHeader: React.FC = observer(() => {
  const [isUpdatingServiceWorker, setIsUpdatingServiceWorker] = React.useState(false)

  const { AppConfig } = useStores()

  const toast = useToast()

  if (AppConfig.IsServiceWorkerAdded && !AppConfig.hasShownOfflineAvailableAlert) {
    toast({
      position: 'top',
      title: 'Sidan fungerar nu offline!',
      status: 'success',
      description: 'Du kan nu öppna sidan utan internet och fortsätta jobba.',
      duration: 10000,
      isClosable: true,
      variant: 'solid'
    })
    AppConfig.SetHasShownOfflineAvailableAlert(true)
  }

  const updateSW = (): void => {
    setIsUpdatingServiceWorker(true)
    AppConfig.UseWaitingServiceWorker()
  }

  if (AppConfig.waitingServiceWorker) {
    return (
      <Box>
        <Alert alignItems="start" display="flex" flexDir="column" id="update" status="warning">
          <Icon marginRight="1rem" name="info" size="25px" />
          <Text>Det finns en uppdatering av den här applikationen tillgänglig.</Text>
          {isUpdatingServiceWorker ? (
            <Spinner className="spinner" />
          ) : (
            <Button className="btn accept" variant="solid" onClick={updateSW}>
              Uppdatera nu
            </Button>
          )}
        </Alert>
      </Box>
    )
  }

  return <></>
})
