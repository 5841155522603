import React from 'react'
import { v4 as uuid } from 'uuid'

import { observer } from 'mobx-react'
import { Button } from '@chakra-ui/core'

import { ICall } from 'models/Call'

export interface IFileUploaderProps {
  accept?: string
  call: ICall
  disabled?: boolean
}

export const FileUploader = observer(
  ({ disabled, call: auditPoint, accept }: IFileUploaderProps): JSX.Element => {
    const inputRef = React.useRef<HTMLInputElement | null>(null)

    const onUploadClick = (): void => {
      if (inputRef.current) {
        inputRef.current.click()
      }
    }

    const onFileInput = (): void => {
      if (inputRef.current && inputRef.current.files && inputRef.current.files.length > 0) {
        const now = new Date()

        for (let i = 0; i < inputRef.current.files.length; i++) {
          const file = inputRef.current.files[i]

          const fileName = uuid() + file.name

          auditPoint.AddImage(fileName, file, now)
        }
      }
    }

    return (
      <>
        <Button className="upload-image-buton" isDisabled={disabled} width="100%" onClick={onUploadClick}>
          Ladda upp bild
        </Button>
        <input ref={inputRef} hidden accept={accept} disabled={disabled} type="file" onInput={onFileInput} />
      </>
    )
  }
)
