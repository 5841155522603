import { AUCTIONS, CALLS, IMAGES } from 'constants/names'
import { getCallsFirestorePath } from './calls'

export function getImagesFirestorePath(auctionId: string, callId: string, imageId = ''): string {
  return `${getCallsFirestorePath(auctionId, callId)}/${IMAGES}/${imageId}`
}

export function getImageStoragePath(auctionId: string, callId: string): string {
  return `${AUCTIONS}/${auctionId}/${CALLS}/${callId}/${IMAGES}`
}

export function isBlobUrl(url: string): boolean {
  return url.startsWith('blob:')
}

export function createOfflineUrl(blob: Blob): string {
  const splitted = blob.type.split('/')

  let type = undefined
  if (splitted.length > 1) {
    type = splitted[1]
  }

  console.log('createOfflineUrl: blob.type =', blob.type)
  return `${(window.URL || window.webkitURL).createObjectURL(blob.slice())}${type ? `.${type}` : ''}`
}
