import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { observer } from 'mobx-react'

import { useStores } from '../models'

export const PrivateRoute: React.FC<RouteProps> = observer(({ children, ...rest }) => {
  const { User } = useStores()

  const { isSignedIn } = User // If we use isSignedIn in render we are in "another" component and will therefore loose observer

  return (
    <Route
      {...rest}
      render={({ location }): React.ReactNode =>
        isSignedIn ? children : <Redirect to={{ pathname: '/login', state: { from: location } }} />
      }
    />
  )
})
