import { observable } from 'mobx'
import { flow, types } from 'mobx-state-tree'
import { IMAGE_DIMENSIONS } from 'config/Images'

export type TFacingMode = 'user' | 'environment'

// TODO: Implement constraints param
export const createCameraStream = async (facingMode: TFacingMode): Promise<MediaStream> =>
  await navigator.mediaDevices.getUserMedia({
    audio: false,
    video: {
      facingMode: facingMode,
      width: {
        max: IMAGE_DIMENSIONS.width
      },
      height: {
        max: IMAGE_DIMENSIONS.height
      }
    }
  })

export const AppConfig = types
  .model({
    waitingServiceWorker: types.maybe(types.frozen<ServiceWorker>()),
    hasShownOfflineAvailableAlert: false
  })
  .extend((self) => {
    const _isOffline = observable.box(!navigator.onLine)
    const cameraStream = observable.box<MediaStream | undefined>(undefined)

    window.addEventListener('online', () => {
      _isOffline.set(false)
    })
    window.addEventListener('offline', () => {
      _isOffline.set(true)
    })

    return {
      views: {
        get isOffline(): boolean {
          return _isOffline.get()
        }
      },
      actions: {
        WaitingServiceWorkerActivatedCallback: (): void => {
          if (self.waitingServiceWorker?.state === 'activated') {
            self.waitingServiceWorker = undefined
            window.location.reload()
          }
        },
        SetHasShownOfflineAvailableAlert: (value: boolean): void => {
          self.hasShownOfflineAvailableAlert = value
        },
        SetIsOffline: (value: boolean): void => {
          _isOffline.set(value)
        },
        GetCameraStream: flow(function* getCameraStream(
          facingMode: TFacingMode
        ): Generator<any, MediaStream | undefined, any> {
          let stream = cameraStream.get()

          if (!stream) {
            stream = yield createCameraStream(facingMode)
            cameraStream.set(stream)
          }

          return stream?.clone()
        }),
        CloseCameraSteam: (): void => {
          const stream = cameraStream.get()
          if (stream) {
            stream.getTracks().forEach((track) => track.stop())
            cameraStream.set(undefined)
          }
        }
      }
    }
  })
  .extend((self) => {
    const serviceWorkerAdded = observable.box(false)

    return {
      views: {
        get IsServiceWorkerAdded(): boolean {
          return serviceWorkerAdded.get()
        }
      },
      actions: {
        AddNewAvailableServiceWorker: (registration: ServiceWorkerRegistration): void => {
          self.waitingServiceWorker = registration.waiting || undefined
        },
        ServiceWorkerAdded: (): void => {
          serviceWorkerAdded.set(true)
        },

        /**
         * NOTE: USING THIS WILL RELOAD THE PAGE
         */
        UseWaitingServiceWorker: (): void => {
          if (self.waitingServiceWorker) {
            self.waitingServiceWorker.addEventListener('statechange', self.WaitingServiceWorkerActivatedCallback)

            self.waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
          }
        }
      }
    }
  })
