import { v4 as uuidv4 } from 'uuid'

import { IMAGE_DIMENSIONS } from 'config/Images'

export const createUniquieImageName = (fileFormat: string): string => `${uuidv4()}.${fileFormat}`

export const getCameraStream = async (): Promise<MediaStream> => {
  const stream = await navigator.mediaDevices.getUserMedia({
    audio: false,
    video: {
      facingMode: 'environment',
      width: { min: IMAGE_DIMENSIONS.width, max: IMAGE_DIMENSIONS.width },
      height: { min: IMAGE_DIMENSIONS.height, max: IMAGE_DIMENSIONS.height }
    }
  })

  return stream
}
