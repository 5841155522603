import * as Sentry from '@sentry/browser'
import { Instance, SnapshotIn, applySnapshot, flow, getEnv, getSnapshot, types } from 'mobx-state-tree'

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

import { IImage, Image } from './Images'
import { IModelsEnvironment } from 'app'
import { IGetImagesReturnType } from 'app/temp/OfflineQueue'
import { AUCTIONS, CALLS, IMAGES } from 'constants/names'
import { createOfflineUrl, getImageStoragePath, getImagesFirestorePath } from 'utils/images'

export const minBidValidationRegex = /^\d+$/ // If this is changed, change the help text in (component) "NewCallForm"
export const callNumberValidationRegex = /^\d+$/

export const Call = types
  .model({
    auctionID: types.string,
    id: types.identifier,
    minBid: types.number,
    headline: types.string,
    callNumber: types.number,
    comment: types.string,
    images: types.map(Image)
  })
  .actions((self) => ({
    SetMinBid: (value: number): void => {
      self.minBid = value
    },
    SetHeadline: (value: string): void => {
      self.headline = value
    },
    SetCallNumber: (value: number): void => {
      self.callNumber = value
    },
    SetComment: (value: string): void => {
      self.comment = value
    },
    SetImage: (imageID: string, image: IImage): void => {
      self.images.set(imageID, image)
    },
    _deleteImage: (imageID: string): void => {
      if (self.images.has(imageID)) {
        self.images.delete(imageID)
      }
    }
  }))
  .actions((self) => ({
    _updateImageFirestoreDoc: (imageId: string, data: Partial<SnapshotIn<IImage>>): void => {
      firebase.firestore().doc(getImagesFirestorePath(self.auctionID, self.id, imageId)).update(data)
    },
    UpdateImageData: (imageID: string, data: SnapshotIn<IImage>): IImage => {
      let image = self.images.get(imageID)

      if (image) {
        applySnapshot(image, data)
      } else {
        image = Image.create(data)

        self.SetImage(imageID, image)
      }

      return image
    }
  }))
  .extend((self) => {
    const storagePath = `callImages/${self.auctionID}/${self.id}`

    let unsub: undefined | (() => void)

    const _onAddImage = (imageID: string, imageData: SnapshotIn<IImage>): void => {
      const image = self.UpdateImageData(imageID, imageData)

      if (image.isUploaded) {
        // firebase
        //   .storage()
        //   .ref(`${storagePath}/${imageData.filename}`)
        //   .getDownloadURL()
        //   .then((url: string | undefined) => {
        //     if (url) {
        //       // TODO: When replacing the URL, if it's a local reference, destroy it (see also Image.ts: Upload)
        //       image?.SetUrl(url)
        //     }
        //   })

        // If we have the image offline, remove it locally
        const env = getEnv<IModelsEnvironment>(self)
        env.offlineQueue.RemoveImage(self.id, imageData.filename)

        // self._updateImageFirestoreDoc(image.id, {
        //   deleted: true
        // } as any)
      }
    }
    // const _onModifyImage = (image: IImage): void => {
    //   console.log('_onModifyImage', image)
    //   const index = self.images.findIndex((i) => i.filename === image.filename)
    //   if (index === -1) {
    //     _onAddImage(image)
    //   } else {
    //     // We need to get the url here, since when we replace it we loose it
    //     const url = image.url || self.images[index].url
    //     self._replaceImageAt(index, { ...image, url })
    //   }
    // }

    return {
      views: {
        get allImagesArr(): IImage[] {
          return Array.from(self.images.values())
        },
        get allImagesCount(): number {
          return this.allImagesArr.length
        },
        get offlineImages(): IImage[] {
          return this.allImagesArr.filter((image) => image.isUploaded === false)
        },
        get offlineImagesCount(): number {
          return this.offlineImages.length
        },
        get imagesSorted(): IImage[] {
          return this.allImagesArr.slice().sort((a, b) => {
            let timeA: firebase.firestore.Timestamp | undefined
            let timeB: firebase.firestore.Timestamp | undefined

            // TODO: How do we avoid this? The object is the same but the function toMillis might or might not exist
            if (a.imageTaken) {
              if (!!a.imageTaken.toMillis) {
                timeA = a.imageTaken
              } else {
                timeA = new firebase.firestore.Timestamp(a.imageTaken.seconds, a.imageTaken.nanoseconds)
              }
            }

            if (b.imageTaken) {
              if (!!b.imageTaken.toMillis) {
                timeB = b.imageTaken
              } else {
                timeB = new firebase.firestore.Timestamp(b.imageTaken.seconds, b.imageTaken.nanoseconds)
              }
            }

            if (timeA !== undefined && timeB !== undefined) return timeA.toMillis() - timeB.toMillis()
            console.log('timeA or timeB did not have imageTaken.toMillis', timeA, timeB)
            return 0
          })
        },
        get imagesCount(): number {
          return self.images.size
        }
      },
      actions: {
        AddImage: (filename: string, imageData: Blob, imageTaken: Date = new Date()): void => {
          const env = getEnv<IModelsEnvironment>(self)

          const url = createOfflineUrl(imageData.slice())

          const docRef = firebase.firestore().collection(getImageStoragePath(self.auctionID, self.id)).doc()

          const imageStoragePath = `${storagePath}/${filename}`

          const { id } = docRef

          console.log('creating image...')
          const image = self.UpdateImageData(
            id,
            Image.create({
              callID: self.id,
              filename,
              id,
              imageTaken: firebase.firestore.Timestamp.fromDate(imageTaken),
              url,
              storagePath: imageStoragePath
            })
          )

          docRef.set(image).then(async () => {
            // The upload is now complete. Create som error checks to make sure everything is fine
            const imageDoc = await firebase.firestore().doc(docRef.path).get()
            const imageData = imageDoc.data()

            if (!imageData || !imageData.callID || !imageData.imageTaken) {
              Sentry.captureException(new Error('An image-document was created in Call.ts but no data was found'), {
                extra: {
                  callID: self.id,
                  imageID: docRef.id,
                  filename
                }
              })
            }
          })

          // Add to queue
          env.offlineQueue.AddImage(self.id, image, imageData, imageStoragePath)
        },
        RemoveImage: (filename: string): void => {
          let imageID

          self.images.forEach((val, key) => {
            if (val.filename === filename) {
              imageID = key
            }
          })

          firebase.firestore().doc(`${AUCTIONS}/${self.auctionID}/${CALLS}/${self.id}/${IMAGES}/${imageID}`).delete()

          const env = getEnv<IModelsEnvironment>(self)
          env.offlineQueue.RemoveImage(self.id, filename)
        },
        ListenToImages: (): void => {
          if (!!unsub) return

          unsub = firebase
            .firestore()
            .collection(`${AUCTIONS}/${self.auctionID}/${CALLS}/${self.id}/${IMAGES}`)
            .onSnapshot((snapshot) => {
              console.log('got image snapshot', snapshot, snapshot.docs)
              snapshot.docChanges().forEach(({ doc, type }) => {
                const docData = doc.data()

                const data: SnapshotIn<IImage> = Image.create({
                  ...(docData as Omit<IImage, 'id' | 'callID' | 'url'>),
                  // imageTaken: !!docData.imageTaken
                  //   ? (docData.imageTaken as firebase.firestore.Timestamp).toDate()
                  //   : NaN, // This is BAD, makes the app crash sometimes
                  id: doc.id,
                  callID: self.id
                })

                switch (type) {
                  case 'modified':
                  // _onModifyImage(data)
                  // break
                  case 'added':
                    _onAddImage(doc.id, data)
                    break
                  case 'removed':
                    self._deleteImage(doc.id)
                    break
                }
              })
            })
        },
        StopListenToImages: (): void => {
          if (unsub) unsub()
          unsub = undefined
        },
        LoadOfflineImages: flow(function* () {
          try {
            const env = getEnv<IModelsEnvironment>(self)

            const storedImages: IGetImagesReturnType[] = yield env.offlineQueue.GetOfflineImagesForCall(self.id)

            console.log('stored images', storedImages.length)
            storedImages.forEach(({ firebaseStoragePath, image, imageData }) => {
              if (image.isUploaded) return

              const myImage = self.UpdateImageData(image.id, image)

              myImage.Upload(imageData, firebaseStoragePath)
            })
          } catch (e) {
            console.error(e)
            Sentry.captureException(e)
          }
        })
      }
    }
  })
  .actions((self) => ({
    afterCreate: (): void => {
      self.LoadOfflineImages()
    }
  }))

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICall extends Instance<typeof Call> {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICallData extends SnapshotIn<typeof Call> {}
