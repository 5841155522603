// import { ImageOfflineUploadQueue } from 'api/ImageUploadQueue'
import { OfflineQueue } from './temp/OfflineQueue'

import { RootStore, TRootStore } from '../models/RootStore'

export interface IModelsEnvironment {
  offlineQueue: OfflineQueue
}

export const createEnvironment = (): IModelsEnvironment => {
  // const uploadQueue = new UploadQueue()
  const offlineQueue = new OfflineQueue()

  return {
    offlineQueue
  }
}

// This function is for setting up things regarding the root store
export const setupRootStore = (environment: IModelsEnvironment): TRootStore => {
  // Might add an environment later to facilitate easier use with firebase + mobx
  const rootStore = RootStore.create({ User: {}, AppConfig: {} }, environment)

  return rootStore
}
