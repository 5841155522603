import * as React from 'react'
import * as Sentry from '@sentry/browser'

import { Page } from './Page'
import { LogOutButton } from 'components/LogOutButton'
import { useStores } from 'models'
// import { IGetImagesReturnType } from 'app/temp/OfflineQueue'

import placeholderImage from './1080.png'

interface IRes {
  imageID: string
  imageData: Blob
  auctionName: string
  callName: string
  callNumber: number
}

function OfflineImage({ auctionName, callName, callNumber, imageData }: IRes): JSX.Element {
  const [isImageLoaded, setIsImageLoaded] = React.useState(false)
  const [imageUrl, setImageUrl] = React.useState<string>()

  React.useEffect(() => {
    ;(async (): Promise<void> => {
      const url = (window.URL || window.webkitURL).createObjectURL(imageData.slice())

      setImageUrl(url)
      setIsImageLoaded(true)
    })()
  }, [])

  return (
    <div>
      <h4>Auktion: {auctionName}</h4>
      <h5>Rop: {callName}</h5>
      <h6>Ropnummer: {callNumber}</h6>
      {isImageLoaded ? <img src={imageUrl} /> : <img src={placeholderImage} />}
    </div>
  )
}

export function OfflineImagesPage(): JSX.Element {
  const [isLoadingImages, setIsLoadingImages] = React.useState(true)
  const [errorLoadingImages, setErrorLoadingImages] = React.useState(false)
  const [imagesDataToShow, setImagesDataToShow] = React.useState<IRes[]>([])

  const rootStore = useStores()
  const env = rootStore.getEnvironment()

  React.useEffect(() => {
    async function offlineImagesEffect(): Promise<void> {
      const results: IRes[] = []
      const promises: Promise<unknown>[] = []

      try {
        rootStore.User.Auctions.forEach((auction) => {
          auction.calls.forEach((call) => {
            console.log('this is call', call.headline, ' for ', auction.name)

            promises.push(
              env.offlineQueue.GetOfflineImagesForCall(call.id).then((imageResults) => {
                imageResults.forEach(({ imageData, image }) => {
                  results.push({
                    auctionName: auction.name,
                    callName: call.headline,
                    callNumber: call.callNumber,
                    imageData: imageData,
                    imageID: image.id
                  })
                })
              })
            )
          })
        })
      } catch (e) {
        console.error(e)
        setErrorLoadingImages(true)
        Sentry.captureException(e)
      }

      await Promise.all(promises)

      setImagesDataToShow(results)
      setIsLoadingImages(false)
    }

    offlineImagesEffect()
  }, [])

  if (isLoadingImages)
    return (
      <Page headerButton={<LogOutButton />}>
        <p>Laddar bilder...</p>
      </Page>
    )

  if (errorLoadingImages)
    return (
      <Page headerButton={<LogOutButton />}>
        <p>Ett fel uppstod när bilderna laddades</p>
      </Page>
    )

  if (imagesDataToShow.length === 0) {
    return (
      <Page headerButton={<LogOutButton />}>
        <p>Hittade inga sparade bilder</p>
      </Page>
    )
  }

  return (
    <Page headerButton={<LogOutButton />}>
      {imagesDataToShow
        .sort((a, b) => {
          if (a.auctionName === b.auctionName) return a.callNumber - b.callNumber

          return a.auctionName.localeCompare(b.auctionName)
        })
        .map((data) => (
          <OfflineImage key={data.imageID} {...data} />
        ))}
    </Page>
  )
}
