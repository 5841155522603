import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Input } from '@chakra-ui/core'

import { EmailRegex } from 'utils'

export type TResetEmailPasswordFormData = {
  email: string
}

export interface IResetEmailPasswordFormProps {
  disabled?: boolean
  onSubmit: (data: TResetEmailPasswordFormData) => void
}

export const ResetEmailPasswordForm = ({ onSubmit, disabled }: IResetEmailPasswordFormProps): JSX.Element => {
  const { register, handleSubmit } = useForm<TResetEmailPasswordFormData>()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        ref={register({ required: true, pattern: EmailRegex })}
        isDisabled={disabled}
        name="email"
        placeholder="Emailadress"
        type="email"
      />
      <Button className="btn special login right" isDisabled={disabled} type="submit">
        Återställ
      </Button>
    </form>
  )
}
