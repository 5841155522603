import * as React from 'react'
import { MissingImage, addMissingImageFirestoreDocuments, addMissingStorageUrls } from 'utils/FixImages'
import * as sentry from '@sentry/browser'

type FixImagesState = 'not started' | 'running' | 'error' | 'success'

export function FixImages() {
  const [state, setState] = React.useState<FixImagesState>('not started')
  const [auctionId, setAuctionId] = React.useState('')
  const [missingImages, setMissingImages] = React.useState<MissingImage[] | undefined>(undefined)

  const onInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setAuctionId(e.target.value)
  }, [])

  const onUpdateClick = React.useCallback(async () => {
    try {
      if (state === 'running') return
      setState('running')
      setMissingImages(undefined)

      const [_missingImages] = await Promise.all([
        addMissingStorageUrls(auctionId),
        addMissingImageFirestoreDocuments(auctionId)
      ])

      setMissingImages(_missingImages)
      setState('success')
    } catch (error) {
      sentry.captureException(error)
      console.warn('An error occurred while updating auction', error)
      setState('error')
    }
  }, [auctionId, state])

  return (
    <div style={{ padding: '3rem' }}>
      <div>
        <h4>Fixa audit bilder</h4>
      </div>
      <div style={{ display: 'flex', gap: '1rem', marginTop: '0.5rem' }}>
        <input placeholder="auction id" value={auctionId} onChange={onInputChange} />
        <button
          className="btn primary"
          disabled={state === 'running'}
          style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
          onClick={onUpdateClick}
        >
          Uppdatera auktion
        </button>
      </div>
      {state === 'error' && <p style={{ color: 'red' }}>Ett fel uppstod! Vänligen kontakta CDAB</p>}
      {state === 'success' && <p style={{ color: 'green' }}>Uppdaterat!</p>}
      {state === 'running' && <p>Uppdaterar...</p>}

      {(missingImages?.length || 0) > 0 && (
        <div>
          <p>Saknade bilder</p>
          <ul>
            {missingImages
              ?.sort((a, b) => a.callNumber - b.callNumber)
              .map((i) => (
                <li key={`${i.callId}-${i.filename}`}>
                  {i.callHeadline} ({i.callNumber}) saknar bild {i.filename}
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  )
}
