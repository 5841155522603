import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/core'

import { TovekHeader } from 'components/TovekHeader'
import { SWUpdateHeader } from 'components/SWUpdateHeader'

import packageJSON from '../../package.json'

export interface IPageProps {
  hideHeader?: boolean
  headerButton?: JSX.Element
  headerIcon?: JSX.Element
  className?: string
}

export const Page: React.FC<IPageProps> = ({ children, hideHeader = false, headerButton, headerIcon, ...props }) => (
  <Flex
    flexDir="column"
    marginLeft={['0%', '10%', '15%', '20%']}
    marginRight={['0%', '10%', '15%', '20%']}
    marginTop="0"
    {...props}
  >
    {/* Headers */}
    {!hideHeader && <TovekHeader headerButton={headerButton} headerIcon={headerIcon} />}
    <SWUpdateHeader />

    {/* Page Content */}
    <Box margin="0" padding="0" position="relative">
      {children}
    </Box>

    {/* Footer */}
    <Box
      display="grid"
      gridTemplateColumns="max-content auto"
      justifyContent="space-between"
      justifyItems="self-end"
      margin="0"
      padding="0"
    >
      <Text fontFamily="monospace" fontSize="0.875rem" padding="2rem 0.5em 0 0.5em">
        v{packageJSON.version}
      </Text>
    </Box>
  </Flex>
)
