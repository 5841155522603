import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { PrivateRoute } from './PrivateRoute'
import { LoginPage } from './LoginPage'
import { MyAuctionsListPage } from './MyAuctionsListPage'
import { NotFoundPage } from './NotFoundPage'
import { CallsPage } from './CallsPage'
import { OfflineImagesPage } from './OflineImagesPage'
import { FixImages } from './FixImages'
import { CreateUser } from './CreateUser'

export const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute exact path="/">
          <MyAuctionsListPage />
        </PrivateRoute>
        <Route exact path="/login">
          <LoginPage />
        </Route>
        <PrivateRoute path="/calls/:auctionID">
          <CallsPage />
        </PrivateRoute>
        <PrivateRoute path="/offlineimages">
          <OfflineImagesPage />
        </PrivateRoute>
        <PrivateRoute path="/fiximages">
          <FixImages />
        </PrivateRoute>
        <PrivateRoute path="/createuser">
          <CreateUser />
        </PrivateRoute>
        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
