import React from 'react'
import { Box, Heading, ListItem, Text } from '@chakra-ui/core'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'

import { IAuction } from 'models/Auction'
import { useStores } from 'models'

export interface ISharedAuctionsListItemProps {
  auction: IAuction
}

export const SharedAuctionsListItem: React.FC<ISharedAuctionsListItemProps> = observer(({ auction }) => {
  const store = useStores()

  const creator = store.User.Colleagues.find((colleague) => colleague.uid === auction.creator)

  return (
    <ListItem
      alignItems="center"
      bg={auction.isFinished ? 'tovek.auctions.finished.background' : undefined}
      className="auctionLink no-padding-bottom"
      display="flex"
      flexWrap="wrap"
    >
      <Box width={['100%', '50%']}>
        <Link to={`calls/${auction.id}`}>
          <Heading
            as="h3"
            fontWeight="300"
            marginBottom="1.5rem"
            // marginBottom={!!creator ? '0' : undefined}
            marginTop="0"
          >
            {auction.name}
          </Heading>
          {!!creator && (
            <Text>
              Skapad av {creator.firstName} {creator.lastName}
            </Text>
          )}
        </Link>
      </Box>
    </ListItem>
  )
})
