import React from 'react'
import { List } from '@chakra-ui/core'

import { IAuction } from 'models/Auction'
import { AuctionsListItem } from './MyAuctionsListItem'

export interface IMyAuctionsListProps {
  auctions: IAuction[]
  onFinishAuction: (auctionID: string) => void
}

export const MyAuctionsList: React.FC<IMyAuctionsListProps> = ({ auctions, onFinishAuction }) => (
  <List>
    {auctions.map((auction) => (
      <AuctionsListItem key={auction.id} auction={auction} onFinishAuction={onFinishAuction} />
    ))}
  </List>
)
