import { Instance, SnapshotIn, flow, onSnapshot, types } from 'mobx-state-tree'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

import { observable } from 'mobx'
import { Auction, IAuction } from './Auction'

const AUCTIONS = 'auctions'

const UserData = types
  .model({
    isAllowed: types.boolean,
    firstName: types.string,
    lastName: types.string,
    sharedAuctionIDs: types.array(types.string),
    uid: types.identifier
  })
  .actions((self) => ({
    AddSharedAuction: (auction: IAuction): void => {
      firebase
        .firestore()
        .doc(`${AUCTIONS}/${auction.id}`)
        .update({
          allowUserIDs: firebase.firestore.FieldValue.arrayUnion(self.uid)
        })
    },
    RemoveSharedAuction: (auction: IAuction): void => {
      firebase
        .firestore()
        .doc(`${AUCTIONS}/${auction.id}`)
        .update({
          allowUserIDs: firebase.firestore.FieldValue.arrayRemove(self.uid)
        })
    }
  }))

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUserData extends Instance<typeof UserData> {}

export const User = types
  .model({
    Auctions: types.map(Auction),
    Colleagues: types.array(UserData)
  })
  .extend(() => {
    // Use volatile state to let firebase handle authentication persistance
    const _uid = observable.box<string | undefined>(undefined)
    const isFirstTime = observable.box(true)
    const loginErrorMessage = observable.box<string | undefined>(undefined)

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const result = await user?.getIdTokenResult()

        if (!!result.claims.auctioneer) {
          loginErrorMessage.set(undefined)
          _uid.set(user.uid)
        } else {
          if (!isFirstTime.get()) {
            loginErrorMessage.set('Detta nummer har inte tillgång till hemsidan, vänligen kontakta en administratör.')
          }

          try {
            _uid.set(undefined)
            await firebase.auth().signOut()
          } catch (e) {
            if (loginErrorMessage.get() !== undefined) loginErrorMessage.set('')
          }
        }
      } else {
        _uid.set(undefined)
      }

      isFirstTime.set(false)
    })

    return {
      views: {
        get uid(): string | undefined {
          return _uid.get()
        },
        get isSignedIn(): boolean {
          return _uid.get() !== undefined
        },
        get isInitating(): boolean {
          return isFirstTime.get()
        },
        get loginErrorMessage(): string | undefined {
          return loginErrorMessage.get()
        }
      },
      actions: {
        SignOut: flow(function* signOut() {
          yield firebase.auth().signOut()
        })
      }
    }
  })
  .extend((self) => {
    return {
      actions: {
        _onAuctionSnapshot: (snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>): void => {
          const { id } = snapshot
          const data = snapshot.data() as IAuction

          if (self.Auctions.has(id)) {
            const auction = self.Auctions.get(id)

            auction?.SetCreator(data.creator)
            auction?.SetName(data.name)
            auction?.SetIsFinished(!!data.isFinished)
            auction?.SetAllowUserIDs(data.allowUserIDs)
          } else {
            self.Auctions.set(id, { ...data, id })
          }
        }
      }
    }
  })
  .extend((self) => {
    const auctionListenMap = new Map<string, () => void>()

    const addListenerToMap = (auctionID: string, unsub: () => void): void => {
      const oldUnsub = auctionListenMap.get(auctionID)

      if (oldUnsub) {
        oldUnsub()
      }

      auctionListenMap.set(auctionID, unsub)
    }

    return {
      actions: {
        AddAuction: (auctionData: Omit<SnapshotIn<typeof Auction>, 'id' | 'creator' | 'allowUserIDs'>): void => {
          firebase
            .firestore()
            .collection(AUCTIONS)
            .doc()
            .set({
              creator: self.uid,
              allowUserIDs: [self.uid],
              deleted: false,
              ios: false,
              ...auctionData
            })
        },
        ListenToAuction: (auctionID: string): void => {
          if (auctionListenMap.has(auctionID)) return

          const unsub = firebase.firestore().doc(`/${AUCTIONS}/${auctionID}`).onSnapshot(self._onAuctionSnapshot)

          addListenerToMap(auctionID, unsub)
        },
        UpdateAuction: (auctionID: string, auctionData: Partial<IAuction>): void => {
          // This will not resolve if offline, avoid using flow

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { id, ...data } = auctionData // We don't need id in firebase

          firebase.firestore().doc(`/${AUCTIONS}/${auctionID}`).update(data)
        },
        StopListenToAuction: (auctionID: string): void => {
          const unsub = auctionListenMap.get(auctionID)

          if (unsub) {
            unsub()
            auctionListenMap.delete(auctionID)
          }
        },
        DeleteAuction: (auctionID: string): void => {
          const unsub = auctionListenMap.get(auctionID)

          if (unsub) {
            unsub()
          }

          self.Auctions.delete(auctionID)
        },

        ClearColleagues: (): void => {
          self.Colleagues.clear()
        },

        AddColleague: (colleague: IUserData): void => {
          self.Colleagues.push(colleague)
        }
      }
    }
  })
  .extend((self) => {
    let myAuctionsUnsubscriber: undefined | (() => void)

    const isFetchingColleagues = observable.box<boolean>(false)

    return {
      views: {
        get IsFetchingColleagues(): boolean {
          return isFetchingColleagues.get()
        }
      },
      actions: {
        GetUsers: flow(function* getUsers() {
          try {
            isFetchingColleagues.set(true)
            const snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = yield firebase
              .firestore()
              .collection('users/')
              .where('isAllowed', '==', true)
              .where('isActive', '==', true)
              .where('roles.AUCTIONEER', '==', true)
              .get()

            self.ClearColleagues()
            snapshot.docs.forEach((doc) => {
              const { id } = doc
              if (id === self.uid) return

              self.AddColleague({ uid: id, ...doc.data() } as IUserData)
            })
          } catch (e) {
            console.warn('An error occurred while trying to get colleagues: ', e)
          } finally {
            isFetchingColleagues.set(false)
          }
        }),
        ListenToMyAuctions: (): void => {
          if (!!myAuctionsUnsubscriber) return

          myAuctionsUnsubscriber = firebase
            .firestore()
            .collection(AUCTIONS)
            .where('allowUserIDs', 'array-contains', self.uid)
            .where('deleted', '==', false)
            .where('ios', '!=', true)
            .onSnapshot((snapshot) => {
              const addedIDs: string[] = []

              snapshot.docs.forEach((doc) => {
                if (doc.exists) {
                  addedIDs.push(doc.id)
                  self._onAuctionSnapshot(doc)
                }
              })

              Array.from(self.Auctions).forEach(([id]) => {
                if (!addedIDs.includes(id)) {
                  // This auction has been deleted but is cached locally, so remove it
                  // This will not cancel any image-uploads which aren't done so it should not interfere if they want to restore it
                  self.DeleteAuction(id)
                }
              })
            })
        },
        StopListenToMyAuctions: (): void => {
          if (!!myAuctionsUnsubscriber) {
            myAuctionsUnsubscriber()
            myAuctionsUnsubscriber = undefined
          }
        }
      }
    }
  })
