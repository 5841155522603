import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Input, Spinner } from '@chakra-ui/core'

export type TVerificationInputFormData = {
  verificationCode: string
}

export interface IVerificationInputProps {
  onSubmit: (data: TVerificationInputFormData) => void
  isLoading: boolean
}

export const VerificationInput: React.FC<IVerificationInputProps> = ({ isLoading, onSubmit }) => {
  const { register, handleSubmit } = useForm<TVerificationInputFormData>()

  if (isLoading) return <Spinner />

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        ref={register}
        autoComplete="one-time-code"
        inputMode="decimal"
        name="verificationCode"
        placeholder="Verifikationskod"
        type="text"
      />
      <Button className="btn special login right" type="submit">
        Logga in
      </Button>
    </form>
  )
}
