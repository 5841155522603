import { theme } from '@chakra-ui/core'

export const tovekTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    tovek: {
      blueHue: '#0b5498',
      blueTint: '#5487b7',
      blueShade: '#1b458c',
      yellowHue: '#fedc32',
      yellowTint: '#fee770',
      yellowShade: '#e5c62d',
      redHue: '#ff0000',
      greyHue: '#f4f3f3',
      greyTint: '#fdfdfd',
      greyShade: '#dddddd',
      lightHue: '#fbfbfb',
      lightTint: '#ffffff',
      lightShade: '#ededed',
      darkHue: '#000000',
      call: {
        valueBackground: '#fbfbfba1',
        imageStatus: {
          allUploaded: '#c3ffaf',
          uploading: '#fff0a0',
          noImages: '#fcc'
        }
      },
      auctions: {
        finished: {
          background: 'lightgreen'
        }
      }
    }
  }
}
