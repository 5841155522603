import React from 'react'

import { observer } from 'mobx-react'

import { CameraImage } from 'components/CameraImage'

import { ICall } from 'models/Call'

export interface ICallImagesList {
  call: ICall
}

export const CallImagesList = observer(
  ({ call }: ICallImagesList): JSX.Element => {
    const images = call.allImagesArr.reverse()

    return (
      <>
        {images.map((image) => (
          <CameraImage key={image.filename} call={call} image={image} />
        ))}
      </>
    )
  }
)
