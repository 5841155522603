import React from 'react'

import { observer } from 'mobx-react'
import { Accordion, AccordionItem } from '@chakra-ui/core'

import { ICall } from 'models/Call'
import { IAuction } from 'models/Auction'

import { CallAccordionItem } from './CallsAccordionItem'

export interface ICallsListProps {
  auction: IAuction
  latestAddedCallID?: string
}

export const CallsAccordion: React.FC<ICallsListProps> = observer(({ auction, latestAddedCallID }) => {
  const { callsSorted } = auction

  const onBidEdit = (callID: string, value: number): void => {
    auction.UpdateCall(callID, {
      minBid: value
    })
  }
  const onHeadllineEdit = (callID: string, value: string): void => {
    auction.UpdateCall(callID, {
      headline: value
    })
  }

  const onCallNumberEdit = (callID: string, value: number): void => {
    auction.UpdateCall(callID, { callNumber: value })
  }
  const onCallCommentEdit = (callID: string, value: string): void => {
    auction.UpdateCall(callID, { comment: value })
  }

  const onDeleteCall = (call: ICall): void => {
    auction.RemoveCall(call.id)
  }

  const [currentOpenIndex, setCurrentOpenIndex] = React.useState(-1)

  React.useEffect(() => {
    const index = callsSorted.findIndex((call) => call.id === latestAddedCallID)
    setCurrentOpenIndex(index)
  }, [latestAddedCallID, callsSorted])

  const accordionItems = callsSorted.map((call, index) => {
    return (
      <AccordionItem key={call.id} className="accordion-title">
        {({ isExpanded }: { isExpanded: boolean }): JSX.Element => (
          <CallAccordionItem
            key={call.id}
            auctionID={auction.id}
            call={call}
            isExpanded={!!isExpanded}
            isLastAdded={call.id === latestAddedCallID}
            onBidEdit={onBidEdit}
            onCallCommentEdit={onCallCommentEdit}
            onCallNumberEdit={onCallNumberEdit}
            onClick={(): void => {
              setCurrentOpenIndex(currentOpenIndex === index ? -1 : index)
            }}
            onDeleteCall={onDeleteCall}
            onHeadllineEdit={onHeadllineEdit}
          />
        )}
      </AccordionItem>
    )
  })

  return (
    <Accordion allowMultiple={false} allowToggle={true} index={currentOpenIndex}>
      {accordionItems}
    </Accordion>
  )
})
