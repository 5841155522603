import { Instance, getEnv, types } from 'mobx-state-tree'

import { User } from './UserStore'
import { AppConfig } from './AppConfig'
import { IModelsEnvironment } from 'app'

export const RootStore = types
  .model({
    User,
    AppConfig
  })
  .actions((self) => ({
    getEnvironment: (): IModelsEnvironment => {
      return getEnv<IModelsEnvironment>(self)
    }
  }))

export type TRootStore = Instance<typeof RootStore>
