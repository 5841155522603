import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

// Duplicated in functions/create-user
enum Role {
  admin = 'admin',
  auctioneer = 'auctioneer'
}

interface IUserData {
  role: Role
  firstName: string
  lastName: string
  email: string
  tel: string
}

type FormState = 'idle' | 'loading'

async function createUser(userData: IUserData) {
  const createUserFunc = firebase.functions().httpsCallable('createUser')
  const userid = await createUserFunc(userData)

  await firebase.auth().sendPasswordResetEmail(userData.email)
}

export function CreateUser() {
  const [formState, setFormState] = useState<FormState>('idle')
  const [createUserMessage, setCreateUserMessage] = useState<string | undefined>(undefined)
  const { register, handleSubmit, errors, reset } = useForm<IUserData>()

  const onSubmit = useCallback(async (formData: IUserData) => {
    try {
      setFormState('loading')
      setCreateUserMessage(undefined)

      if (!formData.tel.startsWith('+46')) {
        if (formData.tel.startsWith('0')) {
          formData.tel = formData.tel.substring(1)
        }

        formData.tel = `+46${formData.tel}`
      }
      await createUser(formData)

      reset()
      setCreateUserMessage('Användare skapad')
    } catch (error) {
      console.error(error)
      setCreateUserMessage('Ett fel uppstod när användare skulle skapas!! (se console)')
    } finally {
      setFormState('idle')
    }
  }, [])

  const errorKeys = Object.keys(errors)

  return (
    <div style={{ margin: '1rem' }}>
      <h1>Skapa användare</h1>
      <form style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }} onSubmit={handleSubmit(onSubmit)}>
        <label>
          <select ref={register({ required: true })} name="role">
            <option value={Role.auctioneer}>Auktionsförrättare</option>
            <option value={Role.admin}>Administratör</option>
          </select>
        </label>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            Förnamn
            <input ref={register({ required: true })} name="firstName" />
          </label>
        </div>
        <label style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          Efternamn
          <input ref={register({ required: true })} name="lastName" />
        </label>
        <label style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          E-mail
          <input ref={register({ required: true })} name="email" type="email" />
        </label>
        <label style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          Telefonnummer
          <div>
            <span>+46</span>
            <input ref={register({ required: true })} name="tel" type="tel" />
          </div>
        </label>

        {errorKeys.length > 0 && (
          <ul>
            {errorKeys.map((k) => (
              <li key={k}>
                {k}: {(errors as any)[k].type}
                {(errors as any)[k].message !== '' && ` - ${(errors as any)[k].message}`}
              </li>
            ))}
          </ul>
        )}

        <button
          className={formState === 'idle' ? 'primary' : ''}
          disabled={formState === 'loading'}
          style={{ maxWidth: '300px' }}
          type="submit"
        >
          {formState === 'loading' && 'Skapar...'}
          {formState === 'idle' && 'Skapa användare'}
        </button>
      </form>

      <p>{createUserMessage}</p>
    </div>
  )
}
