import React from 'react'
import { Box, FormControl, FormHelperText, IconButton, Input, Text, Textarea } from '@chakra-ui/core'
import { useForm } from 'react-hook-form'

export type TInputMode = 'numeric' | 'text'

export interface IEditableItemProps {
  defaultIsEditing?: boolean
  header: string
  value: string | number
  optional?: boolean
  inputMode?: TInputMode
  /**
   * Only applicable when value is string
   */
  multiline?: boolean
  onEditCompleteCallback: (value: string | number) => Promise<void> | void
  useRealTimeCallback?: boolean
  validationPattern?: RegExp
  validationErrorMessage?: string
}

export type TFormData = Pick<IEditableItemProps, 'value'>

export const EditableItem: React.FC<IEditableItemProps> = ({
  header,
  value,
  optional,
  onEditCompleteCallback,
  inputMode,
  multiline = false,
  defaultIsEditing = false,
  useRealTimeCallback = true,
  validationPattern,
  validationErrorMessage
}) => {
  const { register, handleSubmit, getValues, errors, trigger } = useForm<TFormData>()

  const isNumber = typeof value === 'number'
  const [isEditing, setIsEditing] = React.useState(defaultIsEditing)
  const [hasSelectedInput, setHasSelectedInput] = React.useState(false)

  const inputRef = React.useRef<HTMLTextAreaElement | HTMLInputElement>()

  const onEditClick = (): void => {
    setIsEditing(true)
  }

  React.useEffect(() => {
    if (!hasSelectedInput && isEditing && inputRef.current) {
      inputRef.current.select()
      setHasSelectedInput(true)
    }
  }, [hasSelectedInput, isEditing, inputRef])

  const callback = async ({ value }: TFormData): Promise<void> =>
    onEditCompleteCallback(typeof value === 'number' && Number.isNaN(value) ? 0 : value)

  const formatValue = (value: string | number): string | number => {
    if (isNumber) {
      value = Number.parseInt(value.toString()) || 0
    }
    return value
  }

  const onSubmit = async ({ value }: TFormData): Promise<void> => {
    value = formatValue(value)

    setIsEditing(false)
    setHasSelectedInput(false)
    await callback({ value })
  }

  const [inputTimer, setInputTimer] = React.useState<NodeJS.Timeout | undefined>()
  const onInput = (): void => {
    if (!useRealTimeCallback) return
    if (inputTimer !== undefined) clearTimeout(inputTimer)

    setInputTimer(
      setTimeout(async (): Promise<void> => {
        // Only submit if we still editing
        // If we aren't editing, we have already submitted our value
        // If the form doesn't exist any more (when we aren't editing) values can be undefined
        let { value } = getValues()
        if (value !== undefined) {
          value = formatValue(value)
          const result = await trigger('value')
          if (isEditing && result) callback({ value })
        }
      }, 2500)
    )
  }

  return (
    <Box className="editable-item" marginBottom="0.5rem">
      <Text mr="1rem">{header}: </Text>
      {isEditing ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl display="flex" flexDir="column">
            <Box display="flex" flexDir="row">
              {isNumber === false && multiline ? (
                <Textarea
                  ref={(e: HTMLTextAreaElement): void => {
                    register(e)
                    inputRef.current = e
                  }}
                  defaultValue={value}
                  flexGrow={1}
                  name="value"
                  onInput={onInput}
                />
              ) : (
                <Input
                  ref={(e: HTMLInputElement): void => {
                    register(e, { pattern: validationPattern, required: !optional })
                    inputRef.current = e
                  }}
                  className="editable-value"
                  defaultValue={typeof value === 'number' && Number.isNaN(value) ? 0 : value}
                  flexGrow={1}
                  inputMode={inputMode || isNumber ? 'numeric' : 'text'}
                  name="value"
                  type="text"
                  onInput={onInput}
                />
              )}

              <IconButton aria-label={`Edit ${header}`} className="transparent icon" icon={'check'} type="submit" />
            </Box>

            {errors.value && <FormHelperText color="tovek.redHue">{validationErrorMessage}</FormHelperText>}
          </FormControl>
        </form>
      ) : (
        <>
          <Text className="finished-value" flexGrow={1} minHeight="2rem" whiteSpace="pre-wrap" onClick={onEditClick}>
            {value}
          </Text>
          {/* <IconButton
            aria-label={`Edit ${header}`}
            className="transparent icon"
            icon={isEditing ? 'check' : 'edit'}
            onClick={onEditClick}
          /> */}
        </>
      )}
    </Box>
  )
}
