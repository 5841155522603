import React from 'react'
import ReactDOM from 'react-dom'

import 'webrtc-adapter' // Polyfill for camera usage with older browsers
import 'mobx-react/batchingForReactDom'

import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/firestore'
import 'firebase/auth'

import * as Sentry from '@sentry/browser'

import './index.css'
import { App } from './app'
import packageJSON from '../package.json'

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDERID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
}
// Initialize Firebase
// TODO: Move initialization to its own file

firebase.initializeApp(firebaseConfig)
firebase.analytics()

firebase
  .firestore()
  .enablePersistence({ synchronizeTabs: true })
  .catch(() => {
    // TODO: Alert user that persistence isn't available
  })

firebase
  .auth()
  .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  .catch(() => {
    // TODO: Alert user that persistence isn't available
  })

// Initialize sentry
Sentry.init({
  dsn: 'https://d07911c310334ed4a10ded5e27265544@o201115.ingest.sentry.io/5301130',
  enabled: process.env.NODE_ENV !== 'development',
  release: `${packageJSON.name}@${packageJSON.version}`
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// Moved to App.tsx
