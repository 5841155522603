import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Input, Spinner } from '@chakra-ui/core'

export interface IAddAuctionFormData {
  title: string
}

export const AddAuctionForm: React.FC<{
  onAddAuction: (formData: IAddAuctionFormData) => Promise<boolean>
  isDisabled?: boolean
  showSpinner?: boolean
}> = ({ onAddAuction, showSpinner = false, isDisabled = false }) => {
  const { reset, register, handleSubmit } = useForm<IAddAuctionFormData>()

  const onSubmit = async (formData: IAddAuctionFormData): Promise<void> => {
    if (formData.title.length === 0) return

    const success = await onAddAuction(formData)

    if (success) {
      reset()
    }
  }

  return (
    <form id="newAuction" onSubmit={handleSubmit(onSubmit)}>
      <Input ref={register as any} isDisabled={isDisabled} marginRight="1em" name="title" placeholder="Auktionsnamn" />
      {showSpinner ? (
        <Spinner />
      ) : (
        <Button className="btn primary right" isDisabled={isDisabled} type="submit">
          Lägg till
        </Button>
      )}
    </form>
  )
}
