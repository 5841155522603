import * as Sentry from '@sentry/browser'
import localForage from 'localforage'
import { v4 as uuidv4 } from 'uuid'

import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/firestore'

import { IImage, Image } from 'models/Images'
import { waitFor } from 'utils'

export interface IOfflineForageData {
  callID: string
  image: Omit<IImage, 'url'>
  imageData: Blob

  /**
   * The path in Firebase Storage `imageData` will be saved to, including filename and file format
   */
  firebaseStoragePath: string
}
export interface IGetImagesReturnType {
  image: IImage
  imageData: Blob
  firebaseStoragePath: string
}

// class UploadQueue {
//   static uploadedFilenames: string[]
//   static queue: IOfflineForageData[]
//   static isUploading = false
//   static creator: OfflineQueue
//   static uploaderID: string | undefined

//   constructor(creator: OfflineQueue) {
//     UploadQueue.uploadedFilenames = []
//     UploadQueue.queue = []
//     UploadQueue.creator = creator
//   }

//   private async uploadAllImages(): Promise<void> {
//     const myID = uuidv4()
//     try {
//       if (UploadQueue.isUploading || !!UploadQueue.uploaderID) return
//       UploadQueue.isUploading = true
//       UploadQueue.uploaderID = myID
//       await waitFor(500)

//       for (let i = 0; i < UploadQueue.queue.length; i++) {
//         if (UploadQueue.uploaderID !== myID) return

//         const { image, imageData, firebaseStoragePath } = UploadQueue.queue[i]
//         // console.log('Uploading image...', image.filename)

//         if (UploadQueue.uploadedFilenames.includes(image.filename)) continue

//         const ref = firebase.storage().ref(firebaseStoragePath)

//         const task = ref.put(imageData)

//         task.on(firebase.storage.TaskEvent.STATE_CHANGED, ({ bytesTransferred, totalBytes }) => {
//           // console.log(image)
//           image.SetUploadProgress(bytesTransferred / totalBytes)
//         })

//         const result = await task

//         if (result.state === firebase.storage.TaskState.SUCCESS) {
//           const url = await ref.getDownloadURL()
//           if (url) {
//             image.SetUrl(url)
//           }
//           UploadQueue.uploadedFilenames.push(image.filename)
//           UploadQueue.creator.RemoveImage(image.callID, image.filename)
//         } else {
//           image.SetErrorWhileUploading(true)
//         }
//       }
//     } catch (e) {
//       console.error(e)
//       Sentry.captureException(e)
//     } finally {
//       if (UploadQueue.uploaderID === myID) {
//         UploadQueue.queue = UploadQueue.queue.filter(
//           ({ image: { filename } }) => !UploadQueue.uploadedFilenames.includes(filename)
//         )

//         UploadQueue.isUploading = false
//         UploadQueue.uploaderID = undefined

//         if (UploadQueue.queue.length > 0) setTimeout(this.uploadAllImages, 5 * 1000)
//       }
//     }
//   }

//   public AddImages(...images: IOfflineForageData[]): void {
//     UploadQueue.queue.push(...images)
//     this.uploadAllImages()
//   }
// }

export class OfflineQueue {
  offlineForages: { [name: string]: LocalForage }
  static queue: IOfflineForageData[]
  static isAddingImages = false
  static uploadingID: string | undefined
  // uploadQueue: UploadQueue

  constructor() {
    this.offlineForages = {}
    OfflineQueue.queue = []
    // this.uploadQueue = new UploadQueue(this)
  }

  private async addImages(): Promise<void> {
    let i = 0
    const savedImages: IOfflineForageData[] = []
    const myID = uuidv4()

    try {
      if (OfflineQueue.isAddingImages || !!OfflineQueue.uploadingID) return
      OfflineQueue.isAddingImages = true
      OfflineQueue.uploadingID = myID
      await waitFor(500) // Make sure we're not uploading duplicates

      while (OfflineQueue.queue[i] !== undefined) {
        console.log('OfflineQueue: whiling')
        if (OfflineQueue.uploadingID !== myID) return
        const item = OfflineQueue.queue[i]
        i++

        if (this.offlineForages[item.callID] === undefined) {
          this.offlineForages[item.callID] = localForage.createInstance({ name: item.callID })
        }

        await this.offlineForages[item.callID].setItem<IOfflineForageData>(item.image.filename, item)
        savedImages.push(item)
      }

      OfflineQueue.queue = []
    } catch (e) {
      // console.log('An error occurred while saving images :(')
      console.error(e)
      Sentry.captureException(e)
      const newQueue = []

      // Everything before i has been saved, remove them
      while (OfflineQueue.queue[i] !== undefined) {
        newQueue.push(OfflineQueue.queue[i])
        i++
      }

      OfflineQueue.queue = newQueue
    } finally {
      if (OfflineQueue.isAddingImages && OfflineQueue.uploadingID === myID) {
        OfflineQueue.isAddingImages = false
        OfflineQueue.uploadingID = undefined
        // Start upload here
        // this.uploadQueue.AddImages(...savedImages)
        savedImages.forEach(({ image, imageData, firebaseStoragePath }) => {
          image.Upload(imageData, firebaseStoragePath)
        })
      }
    }
  }

  public async AddImage(callID: string, image: IImage, imageData: Blob, firebaseStoragePath: string): Promise<void> {
    OfflineQueue.queue.push({ callID, image, imageData, firebaseStoragePath })
    await this.addImages()
  }

  public async RemoveImage(callID: string, filename: string): Promise<void> {
    try {
      const index = OfflineQueue.queue.findIndex((item) => item.image.filename === filename)

      if (index !== -1) {
        OfflineQueue.queue.splice(index, 1)
      }

      if (this.offlineForages[callID] === undefined) {
        this.offlineForages[callID] = localForage.createInstance({ name: callID })
      }

      await this.offlineForages[callID].removeItem(filename)
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
    }
  }

  private async GetAllOfflineImagesInForage(callID: string): Promise<IOfflineForageData[]> {
    try {
      const list: IOfflineForageData[] = []

      if (this.offlineForages[callID] === undefined) {
        this.offlineForages[callID] = localForage.createInstance({ name: callID })
      }

      const keys = await this.offlineForages[callID].keys()

      await Promise.all(
        keys.map(async (key) => {
          const item = await this.offlineForages[callID].getItem<IOfflineForageData>(key)

          if (item) {
            list.push(item)
          } else {
            // item was null!
            Sentry.captureException('OfflineQueue: had key without any data! key = ' + key)
          }
        })
      )

      // // console.log('These are ALL offline images', list)
      return list
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      return []
    }
  }

  public async GetOfflineImagesForCall(callID: string): Promise<IGetImagesReturnType[]> {
    try {
      const list = await this.GetAllOfflineImagesInForage(callID)
      // // console.log('ALL returned list', list)

      const { createObjectURL } = window.URL || window.webkitURL
      const x = list
        .filter(({ image }) => image.callID === callID)
        .map(({ image, imageData, firebaseStoragePath }) => ({
          imageData,
          firebaseStoragePath,
          image: Image.create({
            ...image,
            imageTaken: new firebase.firestore.Timestamp(image.imageTaken.seconds, image.imageTaken.nanoseconds),
            url: createObjectURL(imageData)
          })
        }))
      // console.log('list after being filtered is', x)

      return x
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      return []
    }
  }

  public async GetOffineImageUrl(callID: string, imageID: string): Promise<string | undefined> {
    try {
      const list = await this.GetAllOfflineImagesInForage(callID)

      const images = list.filter(({ callID, image }) => callID === callID && image.id === imageID)

      if (images.length !== 0) return

      const { createObjectURL } = window.URL || window.webkitURL
      return createObjectURL(images[0].imageData)
    } catch (error) {
      console.warn('Error getting offline image url:', error)
    }
  }

  // public UploadImages(...images: IOfflineForageData[]): void {
  //   this.uploadQueue.AddImages(...images)
  // }
}
