import React from 'react'
import { observer } from 'mobx-react'
import { Box, Button, Heading } from '@chakra-ui/core'
import { Link } from 'react-router-dom'

import { useStores } from 'models'
import { IAuction } from 'models/Auction'
import { Page } from './Page'
import { AddAuctionForm, IAddAuctionFormData } from 'components/AddAuctionForm'
import { MyAuctionsList } from 'components/MyAuctionsList'
import { SharedAuctionsList } from 'components/SharedAuctionList'
import { LogOutButton } from 'components/LogOutButton'

// import { fixImages } from 'utils/FixImages'

export const MyAuctionsListPage: React.FC = observer(() => {
  const store = useStores()

  // React.useEffect(() => {
  //   fixImages()
  // }, [])

  React.useEffect(() => {
    store.User.ListenToMyAuctions()
    store.User.GetUsers()

    return (): void => {
      store.User.StopListenToMyAuctions()
    }
  }, [store.User])

  const [isAddingAuction, setIsAddingAuction] = React.useState(false)

  const onAddAuction = async (data: IAddAuctionFormData): Promise<boolean> => {
    setIsAddingAuction(true)

    try {
      store.User.AddAuction({ name: data.title })

      setIsAddingAuction(false)
    } catch (e) {
      return false
    }
    return true
  }

  const onFinishAuction = (auctionID: string): void => {
    const auction = store.User.Auctions.get(auctionID)

    if (!!auction) {
      store.User.UpdateAuction(auctionID, { isFinished: !auction.isFinished })
    }
  }

  const myAuctions: IAuction[] = []
  const sharedAuctions: IAuction[] = []

  Array.from(store.User.Auctions).forEach(([, auction]) => {
    if (auction.creator === store.User.uid) myAuctions.push(auction)
    else sharedAuctions.push(auction)
  })

  return (
    <Page headerButton={<LogOutButton />}>
      <Link to="offlineimages">
        <Button mt="1rem">Se offline-bilder</Button>
      </Link>
      <Box>
        <Heading as="h3" className="no-margin-bottom" fontWeight="300" marginLeft="1rem" marginTop="2rem">
          Mina Auktioner
        </Heading>
      </Box>
      <Box>
        {myAuctions.length === 0 ? (
          <p>Inga auktioner hittade. Prova att lägga till en!</p>
        ) : (
          <MyAuctionsList auctions={myAuctions} onFinishAuction={onFinishAuction} />
        )}
      </Box>
      <Box marginBottom="2.5rem" width="100%">
        <hr />
      </Box>
      {sharedAuctions.length > 0 && (
        <>
          <Box>
            <Heading as="h3" className="no-margin-bottom">
              Delade Auktioner
            </Heading>
          </Box>
          <Box>
            <SharedAuctionsList auctions={sharedAuctions} />
          </Box>
        </>
      )}

      <Box marginBottom="2.5rem" width="100%">
        {/* <hr /> */}
      </Box>
      <Box>
        <Heading as="h3" className="no-margin-bottom" fontWeight="300" marginLeft="1rem">
          Skapa Ny Auktion
        </Heading>
      </Box>
      <Box marginTop="1rem">
        <AddAuctionForm isDisabled={isAddingAuction} showSpinner={isAddingAuction} onAddAuction={onAddAuction} />
      </Box>
    </Page>
  )
})
