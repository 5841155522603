import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Input } from '@chakra-ui/core'

import { EmailRegex } from 'utils'

export type TEmailLoginFormData = {
  email: string
  password: string
}

export interface IEmailLoginFormProps {
  onLogin: (data: TEmailLoginFormData) => void
  disabled?: boolean
}

export const EmailLoginForm = ({ onLogin, disabled }: IEmailLoginFormProps): JSX.Element => {
  const { register, handleSubmit } = useForm<TEmailLoginFormData>()

  return (
    <form onSubmit={handleSubmit(onLogin)}>
      <Input
        ref={register({ required: true, pattern: EmailRegex })}
        isDisabled={disabled}
        name="email"
        placeholder="Emailadress"
        type="email"
      />
      <Input
        ref={register({ required: true })}
        isDisabled={disabled}
        name="password"
        placeholder="Lösenord"
        type="password"
      />
      <Button className="btn special login right" isDisabled={disabled} type="submit">
        Logga in
      </Button>
    </form>
  )
}
