import React, { useCallback, useEffect, useState } from 'react'

import { useMachine } from '@xstate/react'
import { captureException, captureMessage } from '@sentry/browser'
import { Button } from '@chakra-ui/core'

import { ICameraMeasurements, ICameraProps } from './Camera.interfaces'
import { cameraMachine } from './cameraMachine'
import './Camera.css'

export const useHasFocus = (): boolean => {
  const [hasFocus, setFocus] = useState(document.visibilityState === 'visible')

  const onVisibilityChange = useCallback(() => {
    const visible = document.visibilityState === 'visible'

    setFocus(visible)
  }, [setFocus])

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange)
    document.addEventListener('focus', onVisibilityChange)

    return (): void => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
      document.removeEventListener('focus', onVisibilityChange)
    }
  }, [])

  return hasFocus
}

export const Camera = ({ onImageTaken }: ICameraProps): JSX.Element => {
  const videoRef = React.useRef<HTMLVideoElement>(null)
  const canvasRef = React.useRef<HTMLCanvasElement>(null)
  const shutter = React.useRef<HTMLDivElement>(null)
  const shutterAudio = React.useRef<HTMLAudioElement>(null)

  const [state, send] = useMachine(cameraMachine)

  const onTakePhoto = (): void => {
    send('CAPTURE')
  }

  const setMeasurements = (): void => {
    if (!videoRef.current) {
      send({ type: 'SETUP_ERROR', errorMessage: 'Internal error: The video element was not set!' })
      return
    }
    if (!canvasRef.current) {
      send({ type: 'SETUP_ERROR', errorMessage: 'Internal error: The canvas element was not set' })
      return
    }

    const { stream } = state.context

    if (!stream) {
      send({ type: 'SETUP_ERROR', errorMessage: "Internal error: stream was undefined when it shouldn't be!" })
      return
    }

    let measurements: ICameraMeasurements | undefined = undefined

    videoRef.current.onplay = (): void => {
      if (!measurements) {
        send({ type: 'SETUP_ERROR', errorMessage: 'Internal error: measurements was not defined' })
        return
      }

      send({
        type: 'SET_CAMERA_MEASUREMENTS',
        measurements
      })
    }

    videoRef.current.onloadedmetadata = (): void => {
      if (videoRef.current) {
        const { videoHeight, videoWidth } = videoRef.current

        const isPotrait = videoHeight > videoWidth

        const sideLength = isPotrait ? videoWidth : videoHeight // shortest side

        const sideDelta = Math.abs(videoWidth - videoHeight) / 2
        const sideMargin = sideDelta / sideLength
        const sideMarginPercentage = sideMargin || 0

        measurements = {
          isPotrait,
          sideLength,
          sideMarginPercentage
        }
        videoRef.current.play()
      }
    }

    // Older browsers may not have srcObject
    if (videoRef.current.srcObject !== undefined) {
      videoRef.current.srcObject = stream
    } else {
      // Avoid using this in new browsers, as it is going away.
      videoRef.current.src = window.URL.createObjectURL(stream as any)
    }
  }

  const playShutterAudio = (): void => {
    try {
      shutterAudio.current?.play()
    } catch (_) {}
  }

  const captureImage = (): void => {
    if (!canvasRef.current) {
      send({ type: 'CAPTURE_ERROR', errorMessage: 'Internal error: canvas element was not set' })
      return
    }
    if (!videoRef.current) {
      send({ type: 'CAPTURE_ERROR', errorMessage: 'Internal error: video element was not set' })
      return
    }
    if (!state.context.measurements) {
      send({ type: 'CAPTURE_ERROR', errorMessage: 'Internal error: measurements was not defined' })
      return
    }

    const { isPotrait, sideLength, sideMarginPercentage } = state.context.measurements

    try {
      playShutterAudio()

      const c = canvasRef.current
      c.width = sideLength
      c.height = sideLength

      const context = c.getContext('2d')

      const margin = sideMarginPercentage * sideLength

      if (isPotrait) {
        context?.drawImage(videoRef.current, 0, 0, sideLength, sideLength, 0, 0, sideLength, sideLength)
      } else {
        context?.drawImage(videoRef.current, margin, 0, sideLength, sideLength, 0, 0, sideLength, sideLength)
      }

      c.toBlob(async (blob) => {
        try {
          if (blob) {
            await onImageTaken(blob, 'jpeg')
          }
          send('CAPTURE_COMPLETE')
        } catch (err) {
          captureException(err)
          send({
            type: 'CAPTURE_ERROR',
            errorMessage: 'Ett fel uppstod när bilden skulle hanteras för uppladdning. Felmeddelandet har rapporterats.'
          })
        }
      })
    } catch (e) {
      captureException(e)
      send({
        type: 'CAPTURE_ERROR',
        errorMessage: 'Ett fel uppstod när bilden skulle fångas. Felmeddelandet har rapporterats.'
      })
    }
  }

  React.useEffect(() => {
    const stateStrings = state.toStrings()
    if (stateStrings.length === 0) {
      captureMessage('cameraMachine reached state with 0 length')
      return
    }

    const stateValue = stateStrings[stateStrings.length - 1]

    if (stateValue === 'initializing.setMeasurements') {
      setMeasurements()
    }

    if (stateValue === 'capturing') {
      captureImage()
    }
  }, [state.value, state.toStrings])

  const turnOffVideo = (): void => {
    if (videoRef.current) {
      const { srcObject } = videoRef.current

      if (!!srcObject && 'getTracks' in srcObject) {
        srcObject.getTracks().forEach((track) => track.stop()) // This causes an error to be thrown. We have to make sure that we are playing before we are stopping!
      }
    }
  }

  React.useEffect(() => {
    send('START')

    return turnOffVideo
  }, [send])

  const isCameraVisible = state.value === 'idle' || state.value === 'capturing'
  const canTakePictures = state.value === 'idle'
  const isCapturing = state.value === 'capturing'

  const { isPotrait, sideLength, sideMarginPercentage } = state.context.measurements || {}

  const hasFocus = useHasFocus()

  React.useEffect(() => {
    if (hasFocus) send('START')
    else turnOffVideo()
  }, [hasFocus])

  return (
    <>
      <button
        hidden={state.value !== 'off'}
        type="button"
        onClick={() => {
          send('START')
        }}
      >
        Visa kameran
      </button>
      <div className="aspect-ratio-box" hidden={!isCameraVisible}>
        <div className="aspect-ratio-box-inside">
          <video
            ref={videoRef}
            autoPlay
            playsInline
            className="video-control"
            height={sideLength}
            hidden={!isCameraVisible}
            style={{
              marginLeft: isPotrait ? '' : !!sideMarginPercentage ? `${-sideMarginPercentage * 100}%` : 0,
              width: isPotrait ? '100%' : 'auto',
              height: isPotrait ? 'auto' : '100%',
              cursor: 'pointer'
            }}
            onClick={canTakePictures ? onTakePhoto : undefined}
          />
        </div>
        <div ref={shutter} className={`shutter ${isCapturing && 'on'}`} />
        <audio ref={shutterAudio}>
          <source src="/camera-shutter-click-08.mp3" />
        </audio>
      </div>

      {state.value === 'error' && (
        <>
          <p>{state.context.errorMessage}</p>
          <p>Prova att ladda om sidan!</p>
        </>
      )}

      {isCameraVisible && (
        <Button
          className="full-width take-photo-button"
          hidden={!isCameraVisible}
          isDisabled={!canTakePictures}
          onClick={onTakePhoto}
        >
          Ta foto
        </Button>
      )}

      <canvas ref={canvasRef} hidden />
    </>
  )
}
